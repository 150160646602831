import * as React from 'react';
import { Dropdown, IDropdownStyles, IDropdownOption, IStackTokens, Stack } from '@fluentui/react';
import { IPackage, PackageStatus } from '../../typings/Packaging';
import { greaterThan } from '../../services/resolver/VersionComparer';

const stackTokens: IStackTokens = { childrenGap: 30 };


const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 155 },
    title: { height: 20, lineHeight: 18 },
    caretDownWrapper: { lineHeight: 20, top: 1 }
};
export function UpdateDropdown(props: { installedPackage: IPackage, onChange: Function }) {
    const options = () => {
        const updateDropdownOptions: IDropdownOption[] = [{
            disabled: props.installedPackage.status === PackageStatus.New,
            key: props.installedPackage.id, 
            text: '-- select version --',
            id: JSON.stringify({
                id: props.installedPackage.id,
                version: '0'
            })
        }];

        for (let i = 0; i < props.installedPackage.versions.length; i++) {
            let version = props.installedPackage.versions[i]; 
            if (greaterThan(version.version, props.installedPackage.currentVersionNumber)) {
                version.friendlyId = props.installedPackage.friendlyId;
                let json = JSON.stringify(version);
                updateDropdownOptions.push({
                    disabled: props.installedPackage.status === PackageStatus.New,
                    key: props.installedPackage.id + version.version,
                    text: version.version,
                    id: json
                });
            }
        }

        return updateDropdownOptions;
    };

    const onChange = (json: string | undefined) => {
        if (json) {
            props.onChange(JSON.parse(json));
        }
    };

    return (
        <Stack horizontal tokens={stackTokens} verticalAlign="start">
            <Dropdown
                options={options()}
                styles={dropdownStyles}
                selectedKey={props.installedPackage.selected ? props.installedPackage.id + props.installedPackage.selected.version : props.installedPackage.id}
                onChange={(event, option) => onChange(option?.id)}
            />
        </Stack>
    );
};
