export class ImportProcess {
    private token: CancellationToken;
    private success: boolean;

    constructor() {
        this.token = new CancellationToken();
        this.success = false;
    }

    finish(result: boolean = true) {
        this.success = result;
        this.token.cancel();
    }

    isSuccess() { 
        return this.success;
    }

    isPending(){
        return !this.token.isCancelled();
    }
}

class CancellationToken {
    private cancelled: boolean;

    constructor() {
        this.cancelled = false;
    }

    cancel() {
        this.cancelled = true;
    }

    isCancelled() {
        return this.cancelled;
    }
}