import * as React from 'react';
import { IconButton, IIconProps, IColumn, IListProps, ShimmeredDetailsList, SelectionMode, Modal, IButtonStyles, Icon, IIconStyles } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { getModalStyles } from '../../services/utils/Utils';
import { ErrorModal } from './ErrorModal';
import { IUpdateLogItem } from '../../typings/UpdateLog';

const columns: IColumn[] = [
    { key: "id", name: "Solution", fieldName: "solutionId", minWidth: 200 },
    { key: "previousVersion", name: "Previous version", fieldName: "previousVersion", minWidth: 150 },
    { key: "updatedVersion", name: "Updated version", fieldName: "updatedVersion", minWidth: 150 },
    { key: "successfullyInstalled", name: "Status", minWidth: 100 },
    { key: "download", name: "", minWidth: 100 }
];
const linkStyling = {
    cursor: 'pointer',
    color: '#0078D4',
    textDecoration: 'underline'
};
const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0
};
// modal to show release notes of solution
export function UpdateLogModal(props: { downloadImportLog: (importJobId: string, solutionId: string, solutionVersion: string) => void, updateLogItems: IUpdateLogItem[], isDialogOpen: boolean, hideLogDialog: any }) {
    const [logItem, setLogItem] = React.useState<IUpdateLogItem>();

    const [isModalOpen, { setFalse: hideModal, setTrue: showModal }] = useBoolean(false);
    const onRenderItemColumn = (item: any, index: number | undefined, column: IColumn | undefined): JSX.Element | string | number | undefined => {
        if (column?.key === "successfullyInstalled") {
            if (item.successfullyInstalled === true) {
                if(item.errorMessage){
                    return <div>
                    <span>Succeeded <Icon iconName="InfoSolid" title="Succeeded with warnings. Click to view warning" styles={iconStyles} onClick={() => showError(item)} /></span>
                </div>
                }
                return "Succeeded";
            }
            if (item.successfullyInstalled === false) {
                return <div>
                    <span>Failed <Icon iconName="InfoSolid" title="Click to view error message" styles={iconStyles} onClick={() => showError(item)} /></span>
                </div>
            }

            return "-";
        }
        if (column?.key === "download") {
            if (item.importJobId) {
                return <span style={linkStyling} onClick={() => downloadImportLog(item)}>Download log</span>
            }

            return '';
        }

        return item[column?.fieldName as keyof IUpdateLogItem];
    };

    function showError(item: IUpdateLogItem) {
        setLogItem(item);
        showModal();
    }

    async function downloadImportLog(item: IUpdateLogItem) {
        props.downloadImportLog(item.importJobId ? item.importJobId : '', item.solutionId, item.updatedVersion);
    }

    return (
        <div>
            <Modal
                isOpen={props.isDialogOpen}
                onDismiss={props.hideLogDialog}
                isBlocking={false}
                containerClassName={contentStyles.container}
            >
                <div className={contentStyles.header}>
                    <span>Log items</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Sluit"
                        onClick={props.hideLogDialog}
                    />
                </div>
                <div className={contentStyles.body}>
                    <ShimmeredDetailsList
                        setKey="logitems"
                        items={props.updateLogItems || []}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        onRenderItemColumn={onRenderItemColumn}
                        enableShimmer={!props.updateLogItems.length}
                        listProps={shimmeredDetailsListProps} />
                </div>
            </Modal>
            <ErrorModal logItem={logItem} isModalOpen={isModalOpen} hideModal={hideModal} />
        </div>
    );
}

// styling for modal
const cancelIcon: IIconProps = { iconName: 'Cancel', color: 'black' };
const contentStyles = getModalStyles();
const iconButtonStyles: IButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginRight: '4px'
    }
};
const iconStyles: Partial<IIconStyles> = {
    root: { cursor: 'pointer', verticalAlign: 'text-top' }
};