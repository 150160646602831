export interface IProxy{
    Invoke(method: string, ...args: any[]): Promise<any>;
    crmInfo: ICrmInfo;
    connect(): Promise<void>;
    getAuthorizationData() : ({ organizationId: string, hostname: string });
}

export function generateRandomString(): string {
    return (Math.random() + 1).toString(16).substring(7)
}

export interface IDispatched {
    resolve: <IResponse>(value?: IResponse | Promise<IResponse>) => void;
    reject: <IResponse>(error: IResponse) => void;
    promise: Promise<any>;
}
export interface IDispatchedCache {
    [messageId: string]: IDispatched;
}
export interface ICrmInfo{
    hostName: string;
    organizationId: string;
    proxyVersion: string;
}
export const apiVersion: string = 'v9.0';

// needed for old proxy
export interface IResponse{
    requestId: string;
    statusCode: number;
    ok: boolean;
    result: any;
    crmInfo: ICrmInfo;
}
export interface IRequest{
    requestId: string;
    url: string;
    init: RequestInit;
}
export enum ImportType {
    Update,
    Upgrade
}
export interface IImportJob {
    id?: string;
    solutionName: string;
    version: string;
    importType: ImportType;
    progress: number;
    errorCode?: string;
    errorText?: string;
    data?: string;
    trialRequestComplete?: boolean;
    active: boolean;
    success: boolean;
}