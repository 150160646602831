import * as React from 'react';
import {
    initializeIcons, ShimmeredDetailsList, IColumn, SelectionMode, IListProps,
    ScrollablePane, ScrollbarVisibility, IRenderFunction, IDetailsHeaderProps, IDetailsColumnRenderTooltipProps,
    StickyPositionType, TooltipHost, Sticky, Panel, PanelType
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { IUpdateLog, IUpdateLogItem } from '../../typings/UpdateLog';
import { UpdateLogModal } from './UpdateLogModal';
import { IPackage } from '../../typings/Packaging';
import { Steps } from './Steps';
import { updateLogService, packageService, setup } from '../solutions/SolutionInstaller';
import { Toggle } from '@fluentui/react';

const columns: IColumn[] = [
    { key: "items", name: "Solutions", minWidth: 200 },
    { key: "status", name: "Status", minWidth: 200 },
    { key: "importType", name: "Action", minWidth: 50 },
    { key: "timestamp", name: "Timestamp (UTC)", fieldName: "timestamp", minWidth: 120 },
    { key: "details", name: "", minWidth: 40 },
    { key: "steps", name: "", minWidth: 40 },
];

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0
};

export function UpdateLogs(props: { downloadImportLog: (importJobId: string, solutionId: string, solutionVersion: string) => void, isPanelOpen: boolean, closePanel: Function }) {
    initializeIcons();

    const [initialLogs, setInitialLogs] = React.useState<IUpdateLog[] | null>(null);
    const [updateLogs, setUpdateLogs] = React.useState<IUpdateLog[] | null>(null);
    const [updateLogItems, setUpdateLogItems] = React.useState<IUpdateLogItem[]>([]);

    const [showAll, { toggle: toggleShowAll }] = useBoolean(false);

    const [isDialogOpen, { setTrue: showLogModal, setFalse: hideLogDialog }] = useBoolean(false);
    const [isStepsModalOpen, { setTrue: showStepsModal, setFalse: hideStepsDialog }] = useBoolean(false);

    const linkStyling = {
        cursor: 'pointer',
        color: '#0078D4',
        textDecoration: 'underline'
    };
    const onRenderItemColumn = (item: any, index: number | undefined, column: IColumn | undefined): JSX.Element | string | number | undefined => {
        var log = item as IUpdateLog;
        if (column?.key === "items") {
            return log.items.map((elem: IUpdateLogItem) => {
                return `${elem.solutionId} ${elem.updatedVersion}`;
            }).join(", ");
        }
        if (column?.key === "importType") {
            return log.importType === 0 ? 'Update' : 'Upgrade';
        }
        if (column?.key === "status") {
            var succeeded = log.items.filter(x => x.successfullyInstalled === true);
            var failed = log.items.filter(x => x.successfullyInstalled === false);
            var aborted = log.items.filter(x => x.successfullyInstalled === null);

            return `Succeeded: ${succeeded.length}, Failed: ${failed.length}, Aborted: ${aborted.length}`;
        }
        if (column?.key === "details") {
            return <span style={linkStyling} onClick={() => show(item)}>Details</span>;
        }
        if (column?.key === "steps") {
            return <span style={linkStyling} onClick={() => showSteps(item)}>Steps</span>;
        }
        return item[column?.fieldName as keyof IUpdateLog];
    };

    function show(updatelog: IUpdateLog) {
        setUpdateLogItems(updatelog.items);
        showLogModal();
    }

    const [packageUpdates, setPackageUpdates] = React.useState<IPackage[]>([]);
    async function showSteps(updatelog: IUpdateLog) {
        let packages = await packageService.getPackagesByUpdateLog(updatelog);
        setPackageUpdates(packages);
        showStepsModal();
    }


    if (props.isPanelOpen && !updateLogs) {
        setLogs(setInitialLogs, setUpdateLogs);
    }

    function dismissPanel() {
        props.closePanel();
        setUpdateLogs(null);
    }

    function filterLog(){
        if(showAll){
            setUpdateLogs(initialLogs);
        }else{
            const logs = initialLogs?.filter(x => (x.items.find(x => x.successfullyInstalled === false)));
            setUpdateLogs(logs ? logs : []);
        }
        toggleShowAll();
    }

    return (
        <>
            <div>
                <Panel
                    key="panel"
                    headerText="Update History"
                    isOpen={props.isPanelOpen}
                    onDismiss={dismissPanel}
                    closeButtonAriaLabel="Close Update History"
                    styles={{ main: { minWidth: '75%' } }}
                    type={PanelType.custom}
                    customWidth="500px"
                    onOuterClick={() => undefined}
                >
                    <div style={{ maxHeight: '80vh' }}>
                        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} styles={{ root: { top: 50 } }}>
                            <Toggle
                                label="Show failed imports only"
                                onChange={filterLog}
                                onText="Failed only"
                                offText="All"
                                styles={{root: {padding: '0 25px'}}}
                                />
                            <ShimmeredDetailsList
                                setKey="logs"
                                items={updateLogs || []}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                onRenderItemColumn={onRenderItemColumn}
                                onRenderDetailsHeader={onRenderDetailsHeader}
                                enableShimmer={!updateLogs}
                                listProps={shimmeredDetailsListProps} />
                        </ScrollablePane>
                    </div>
                </Panel>
            </div>
            <UpdateLogModal downloadImportLog={props.downloadImportLog} isDialogOpen={isDialogOpen} hideLogDialog={hideLogDialog} updateLogItems={updateLogItems} />
            <Steps isDialogOpen={isStepsModalOpen} hideDialog={hideStepsDialog} packageUpdates={packageUpdates} />
        </>
    );
}
const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
    if (!props) {
        return null;
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
        <TooltipHost {...tooltipHostProps} />
    );
    return (
        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced >
            {defaultRender!({
                ...props,
                onRenderColumnHeaderTooltip,
            })}
        </Sticky>
    );
};
async function setLogs(setInitialLogs: Function, setUpdateLogs: Function, retries: number = 3, backoff: number = 150) {
    if ((!setup.proxy || setup.proxy.crmInfo.hostName) === "" && retries > 0) {
        setTimeout(() => {
            setLogs(setInitialLogs, setUpdateLogs, retries - 1, backoff * 2);
        }, backoff)
    }

    const logs = await updateLogService.getUpdateLogs(setup.proxy.crmInfo.hostName);
    setInitialLogs(logs);
    setUpdateLogs(logs);
}