export interface IHeaders{
    'Content-Type': string;
    'HostName': string;
    'OrganizationId': string;
}
export interface IServiceResult{
    ok: boolean,
    content: any
};
export function CreateHeaders( organizationId: string, hostName: string, contentType = 'application/json'): IHeaders{
    return {
        'Content-Type': contentType,
        'OrganizationId': organizationId,
        'HostName': hostName
    }
}
export async function fetchRetry(url: string, init: object = {}, retries: number = 3, backoff: number = 300): Promise<Response> {
    /* 1 */
    const retryCodes = [500];
    let runRetry = () => {
        setTimeout(() => {
            /* 2 */
            return fetchRetry(url, init, retries - 1, backoff * 2) /* 3 */
        }, backoff) /* 2 */
    }

    let response = await fetch(url, init).then(res => {
        if (retries > 0 && retryCodes.includes(res.status)) {
            runRetry();
        }

        return res;
    }).catch(() => {
        if (retries > 0) {
            runRetry();
        }

        return new Response(null, { status: 500 });
    });

    return response;
}
