import * as React from 'react';
import { Modal, IconButton, IIconProps, IButtonStyles } from '@fluentui/react';
import { getModalStyles } from '../../services/utils/Utils';

// modal to show release notes of solution
export function ServerErrorModal(props: { statusCode: number, isModalOpen: boolean, hideModal: any }) {

    return (
        <div>
            <Modal
                isOpen={props.isModalOpen}
                onDismiss={props.hideModal}
                isBlocking={false}
                containerClassName={modalStyles.container}
            >
                <div className={modalStyles.header}>
                    <span>Error fetching updates</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close"
                        onClick={props.hideModal}
                    />
                </div>
                <div className={modalStyles.body}>
                    <p>
                        Server responded with status code: {props.statusCode}. 
                    </p>
                    <p>
                        {props.statusCode === 403 ? 
                            ' This environment is not registered in Licensing Administration. Register this environment to gain access.'
                        : ' Try refreshing the page.'}
                    </p>
                </div>
            </Modal>
        </div>
    );
};

// styling for modal
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const modalStyles = getModalStyles();
const iconButtonStyles: IButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginRight: '4px'
    }
};