import { setup } from '../../components/solutions/SolutionInstaller';
import { IPackage, IPackageIdentity } from '../../typings/Packaging';
import { IUpdateLog } from '../../typings/UpdateLog';
import { IPackageService, IPackageResult } from './IPackageService';
import { CreateHeaders, fetchRetry, IHeaders, IServiceResult } from '../utils/ServiceUtils';
import { FetchXmlRequest, RetrieveRequestType } from '../proxy/ProxyTypes';
const { REACT_APP_SOLUTIONINSTALLERAPI_BASEURL } = process.env;

export class PackageService implements IPackageService {
    private _packages: IPackage[] = [];
    private _headers: IHeaders;
    private _baseUrl: string;

    constructor(organizationId: string, hostname: string) {
        this._headers = CreateHeaders(organizationId, hostname);
        this._baseUrl = REACT_APP_SOLUTIONINSTALLERAPI_BASEURL ? REACT_APP_SOLUTIONINSTALLERAPI_BASEURL : '';
    }

    async getPackagesByUpdateLog(updateLog: IUpdateLog): Promise<IPackage[]> {
        const url = `${this._baseUrl}/api/packagesByUpdateLog`;
        const init = {
            method: 'POST',
            headers: this._headers,
            body: JSON.stringify(updateLog)
        };
        let response = await fetchRetry(url, init);

        const json = await response.json() as IPackage[];

        return json as IPackage[];
    }

    // get all available updates of installed packages
    getPackageUpdates = async (installed: IPackage[]): Promise<IPackageResult> => {
        const url = `${this._baseUrl}/api/updates`;
        const init = {
            method: 'POST',
            headers: this._headers,
            body: JSON.stringify(installed)
        };

        let response = await fetchRetry(url, init);

        if (response.ok) {
            const json = await response.json() as IPackage[];

            const packages = this.filterUpgrade(json);
            return { statusCode: response.status, value: packages };
        }

        return { statusCode: response.status, value: null };
    }

    // get package with metadata like release notes
    async getPackage(id: string, currentVersion: string): Promise<IPackage> {
        var existing = this._packages.find((p) => (
            p.id === id
        ));

        if (existing) {
            return existing;
        }

        const url = `${this._baseUrl}/api/package?id=${id}&version=${currentVersion}`;
        const init = {
            method: 'GET',
            headers: this._headers
        };

        let response = await fetchRetry(url, init);
        const pack = await response.json() as IPackage;

        this._packages.push(pack);

        return pack;
    }

    async downloadSolution(id: string, version: string): Promise<IServiceResult> {
        const url = `${this._baseUrl}/api/download?id=${id}&version=${version}`;
        const init = {
            method: 'GET',
            headers: this._headers
        };

        const response = await fetchRetry(url, init);

        // could not get solution file
        if (response.status === 200) {    
            return {
                ok: true,
                content: await response.json()
            };
        }
        
        return {
            ok: false,
            content: await response.json()
        };
    }

    async getImportedSolutions(id: string): Promise<(IPackageIdentity[] | null)> {
        const url = `${this._baseUrl}/api/import/${id}`;
        const init = {
            method: 'GET',
            headers: this._headers
        };

        let response = await fetchRetry(url, init);

        if (response.ok) {
            const identitites = await response.json() as IPackageIdentity[];
            
            return identitites;
        }

        return null;
    }

    async getPriorityList(): Promise<string[]> {
        const url = `${this._baseUrl}/api/priority-list`;
        const init = {
            method: 'GET',
            headers: this._headers
        };

        let response = await fetchRetry(url, init);

        if (response.ok) {
            const priorityList = await response.json() as string[];
            
            return priorityList;
        }

        return [];
    }

    filterUpgrade(packages: IPackage[]) {
        for (let i = 0; i < packages.length; i++) {
            const element = packages[i];
            if (element.id.endsWith("_Upgrade")) {
                packages.splice(i, 1);
                var toAlter = packages.filter(x => x.friendlyId === element.friendlyId);
                if (toAlter.length) {
                    toAlter[0].hasUnfinishedUpgrade = true;
                }
                continue;
            }
        }
        return packages;
    }

    async retrieveLicensedSolutions(): Promise<IPackage[]> {
        const fetchXml = '<fetch distinct="false" mapping="logical">'
            + '<entity name="solution">'
            + '<attribute name="uniquename"/>'
            + '<attribute name="version"/>'
            + '<attribute name="friendlyname"/>'
            + '<filter type="or">'
            + '<condition attribute="uniquename" operator="begins-with" value="CRMandMore_"/>'
            + '<condition attribute="uniquename" operator="begins-with" value="FellowmindNL_"/>'
            + '<condition attribute="uniquename" operator="begins-with" value="Fellowmind_"/>'
            + '<condition attribute="uniquename" operator="begins-with" value="CRMPAG_"/>'
            + '</filter>'
            + '</entity>'
            + '</fetch>';

        const solutionsFetchRequest = {
            entitySetName: "solutions",
            fetchXml: fetchXml,
            type: RetrieveRequestType.ByFetchXml,
            apiVersion: "v9.0"
        } as FetchXmlRequest;
    
        const response = await setup.proxy.Invoke('executeQuery', solutionsFetchRequest);

        const installedSolutions: IPackage[] = [];
        for (let i = 0; i < response.value.length; i++) {
            installedSolutions.push({
                id: response.value[i].uniquename,
                friendlyId: response.value[i].friendlyname,
                currentVersionNumber: response.value[i].version,
                versions: []
            });
        }
    
        return installedSolutions;
    }
}
