import * as React from 'react';
import { IPackage } from '../../typings/Packaging';
import { marked } from 'marked';
import { IButtonStyles, Modal, IconButton, IIconProps, initializeIcons, Icon } from '@fluentui/react';
import { getModalStyles } from '../../services/utils/Utils';
import { LoadingSpinner } from '../common/LoadingSpinner';

// modal to show release notes of solution
export function SolutionInfoModal(props: { packageForModal: IPackage | null, isModalOpen: boolean, hideModal: any }) {

    const renderer = {
        blockquote(quote: string) {
            // Add visual pane for note
            if (quote !== undefined) {
                if (quote.includes("[!NOTE]")) {
                    quote = quote.replace('[!NOTE]', '<strong>NOTE:</strong>')
                }
            }
            return '<div class="NOTE alert alert-info">' + quote + '</div>';
        }
    };
    marked.use({renderer})

    return (
        <div>
            <Modal
                titleAriaId={props.packageForModal?.id}
                isOpen={props.isModalOpen}
                onDismiss={props.hideModal}
                containerClassName={contentStyles.container}
            >
                <div className={contentStyles.header} style={{fontSize: '1.5rem'}}>
                    {props.packageForModal ? props.packageForModal.friendlyId + ' Release Notes' : 'Loading...'}
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Sluit"
                        onClick={props.hideModal}
                    />
                </div>
                <div className={contentStyles.body} style={{minHeight: 200, maxHeight: 'calc(100vh - 78px)', overflow: 'auto'}}>
                    {props.packageForModal ? getReleaseNotes(props.packageForModal) : <LoadingSpinner title="Loading Release Notes..."/>}
                </div>
            </Modal>
        </div>
    );
};

// release notes to show in modal
function getReleaseNotes(packageForModal: IPackage): JSX.Element | JSX.Element[] {
    const getHtml = (notes: string) => {
        const markedUp = marked(notes);
        return { __html: markedUp };
    }

    const releaseNotes = packageForModal.versions?.map((update, index) => (
        <div style={{marginTop: 5}} key={update.version}>
            {update.version === packageForModal.currentVersionNumber ? 
                <div style={{color: '#00c100', fontSize: '1.1rem', padding: '0 0 15px'}}>✔ Currently Installed</div> : ''}
            <div dangerouslySetInnerHTML={getHtml(update.releaseNotes ? update.releaseNotes : '')}>
            </div>
            {index < packageForModal.versions.length - 1 ?
            <hr/> : ''}
        </div>
    ));

    if (!releaseNotes.length) {
        return <div className='releaseNotes'>
                    <p>There are no release notes present.</p>
                </div>
    }

    return releaseNotes;
}

// styling for modal
initializeIcons();
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const contentStyles = getModalStyles();
const iconButtonStyles: IButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginRight: '4px',
    }
};