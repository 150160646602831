import { IEventTelemetry } from '@microsoft/applicationinsights-common';
import { ICustomProperties } from '@microsoft/applicationinsights-web';
import { ImportProcess } from '../../services/import/ImportProcess';
import { IPackageIdentity } from '../../typings/Packaging';
import { appInsights } from '../solutions/SolutionInstaller';
import { IProxy } from '../../services/proxy/IProxy';

export class PacoImportProcess{
    private importId: string;
    process: ImportProcess;

    constructor(id: string, private proxy: IProxy){
        appInsights.startTrackEvent("PACO Import")
        this.importId = id;
        this.process = new ImportProcess();
    }

    finish(success: boolean = true, identities: IPackageIdentity[] = []) {
        this.process.finish(success);
        const organizationId = this.proxy.getAuthorizationData().organizationId
        
        appInsights.stopTrackEvent("PACO Import", {
            organizationId: organizationId,
            importId: this.importId
        });

        if(success){
            const event: IEventTelemetry = {
                name: 'PACO Import Success'
            };
            const customProperties: ICustomProperties = {
                organizationId: this.proxy.getAuthorizationData().organizationId,
                solutions: identities.map(x => x.id).join(','),
                importId: this.importId
            };
            appInsights.trackEvent(event, customProperties);
        }else{
            const event: IEventTelemetry = {
                name: 'PACO Import Canceled'
            };
            const customProperties: ICustomProperties = {
                organizationId: organizationId,
                importId: this.importId 
            };
            appInsights.trackEvent(event, customProperties);
        }
    }
}