export type KeyPredicate = string | CompoundKey;
export type CompoundKey = { [key: string]: string | number };
export type EntityName = string;
export type QueryOptions = string | { [key: string]: string };

export interface CollectionNavigation {
    entityName: EntityName;
    key?: KeyPredicate;
}

export interface EntitySetIdentifier {
    entitySetName: EntityName;
    key?: KeyPredicate;
}

export interface EntitySet extends EntitySetIdentifier {
    property?: string | CollectionNavigation;
    queryOptions?: QueryOptions;
}


export type ResourcePath = EntitySet;

export enum RetrieveRequestType {
    ById,
    ByAlternateKey,
    ByFilter,
    ByFetchXml
}

export type ApiVersion = { apiVersion: "v9.0" }

/** This should replace the RetrieveRequest */
export type RetrieveResourceRequest = ApiVersion & ResourcePath;


export type DeleteRequest = ApiVersion & ResourcePath;


export interface RetrieveRequest {
    type: RetrieveRequestType;
    entitySetName: string;
    apiVersion: "v9.0";
}
export interface StoreRequest {
    type: RetrieveRequestType;
    entitySetName: string;
    apiVersion: "v9.0";
    data: any;
}

export type StoreRequest2 = { data: any } & ApiVersion & ResourcePath;

export interface StoreRequestByAlternateKeyRequest extends StoreRequest {
    type: RetrieveRequestType.ByAlternateKey;
    keyname: string;
    keyvalue: string | number;
}

interface InvokeActionRequestContent extends ApiVersion {
    actionName: string;
    data: any;
}

export type InvokeActionRequest = InvokeActionRequestContent & (InvokeUnBoundActionRequest | InvokeBoundActionRequest);

export type InvokeUnBoundActionRequest = { isBound: false };

export type InvokeBoundActionRequest = { isBound: true } & EntitySetIdentifier;


export interface RetrieveByIdRequest extends RetrieveRequest {
    type: RetrieveRequestType.ById;
    id: string;
    columns?: string[];
}

export interface RetrieveByAlternateKeyRequest extends RetrieveRequest {
    type: RetrieveRequestType.ByAlternateKey;
    keyname: string;
    keyvalue: string | number;
    columns?: string[];
}

export interface FilterRequest extends RetrieveRequest {
    type: RetrieveRequestType.ByFilter;
    columns?: string[];
    filters?: FilterOption;
}

export interface FilterOption {
    logicalOperator: "and" | "or";
    conditions: string[];
}

export interface FetchXmlRequest extends RetrieveRequest {
    type: RetrieveRequestType.ByFetchXml;
    fetchXml: string;
}

export interface RetrieveResponse {

}
export interface StoreResponse {
    entityUri?: string;
}
export interface ActionResponse {

}
export interface DeleteResponse { }