import * as React from 'react';
import { Spinner } from '@fluentui/react/lib/Spinner';

export function LoadingSpinner(props: { title: string,  }) {
    return (
      <div style={{ width: '100%', height: '80%', top: 45, left: 0, position: 'absolute', pointerEvents: 'none', background: 'white', zIndex: 10000 }}>
          <div style={{ top: "35%", position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Spinner label={props.title} styles={{ root: { padding: '5px 15px', background: 'white' } }} />
          </div>
      </div>
  );
}