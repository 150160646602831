import * as React from 'react';
import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react';

export async function sleep(milliseconds: number){
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}
export function downloadFile(content: string, filename: string, fileType: string = 'application/json;charset=utf-8'){
    var blob = new Blob([content], {type: fileType});
    var url = URL.createObjectURL(blob);
    var elem = document.createElement('a');
    elem.href = url;
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
}
export function createGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & (0x3 | 0x8));
        return v.toString(16);
    });
}
export function formatXML(input: string){
    let start = input.indexOf("<");
    let end = input.lastIndexOf(">");

    let startingElement = <p key={-100}>{input.substring(0, start).trim()}</p>;
    let finalElement = <p key={-99}>{input.substring(end + 1, input.length).trim()}</p>;

    let xmlString = input.substring(start, end + 1);
    let result =_formatXML(xmlString);

    result.unshift(startingElement);
    result.push(finalElement);
    return result;
}

function _formatXML(xml: string): JSX.Element[] {
    const reg = /(>)(<)(\/*)/g;
    let pad = 0;

    xml = xml.replace(reg, '$1\r\n$2$3');
    let childless = false;
    return xml.split('\r\n').map((node, index) => {
        let indent = 0;

        if (node.match(/^<\/\w/) && pad > 0) {
            // </MissingDependency>
            pad -= 1;
            childless = false;
        } else if (node.match(/^<\w[^>]*[^\\/]>.*$/)) {
            // <MissingDependency>
            indent = 1;
            childless = false;
        } else {
            // <Required/>
            if(!childless){
                indent = 1;
                childless = true;
            }else{
                indent = 0;
            }
        }

        pad += indent;

        let padding = pad * 25;
        return <p key={index} style={{paddingLeft: padding}}>{node}</p>
    });
}

export function getModalStyles() {
    const theme = getTheme();
    const styles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            minHeight: 200,
            minWidth: 500
        },
        header: [
            theme.fonts,
            {
                flex: '1 1 auto',
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'baseline',
                fontWeight: FontWeights.semibold,
                padding: '4px ​4px 4px 24px',
                borderBottom: '1px solid lightgrey',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '10px 24px 24px 24px',
            overflowY: 'hidden',
            minHeight: 95,
            selectors: {
                p: { margin: '10px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
                h3: {
                    fontSize: '1.3rem'
                }
            },
        },
        footer: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
        },
        iconButtonStyles: {
            root: {
                color: theme.palette.neutralPrimary,
                marginLeft: 'auto',
                marginRight: '4px',
            },
            rootHovered: {
                color: theme.palette.neutralDark,
            },
        }
    });
    return styles;
}
export function beautifyXml(xmlString: string, indent: string) {
    indent = indent || "\t"; //can be specified by second argument of the function

    var tabs = "";  //store the current indentation

    var result = xmlString.replace(
        /\s*<.+?>|\s*[^<]+/g, //pattern to match nodes (angled brackets or text)
        function (m) {
            m = m.replace(/^\s+|\s+$/g, "");  //trim the match

            if (/^<[?]xml/.test(m)) return m + "\n";  //if the match is a header, ignore it

            if (/^<[/]/.test(m))  //if the match is a closing tag
            {
                tabs = tabs.replace(indent, "");  //remove one indent from the store
                m = tabs + m;  //add the tabs at the beginning of the match
            }
            else if (/<.*[^>]\/>/.test(m))  //if the match contains an entire node
            {
                //leave the store as is
                m = tabs + m; //add the tabs at the beginning of the match
            }
            else if (/<.*>/.test(m)) //if the match starts with an opening tag and does not contain an entire node
            {
                m = tabs + m;  //add the tabs at the beginning of the matche
                tabs += indent;  //and add one indent to the store
            }
            else  //if the match contain a text node
            {
                m = tabs + m;  // add the tabs at the beginning of the match
            }

            //return m+"\n";
            return "\n" + m; //content has additional space(line) from header
        }
    );

    //Additional fixes
    //result = result.replace(/(<[^\/>]*>)\n\s*(<[\/])/g, "$1$2");  //remove \n between opening and closing tags of the same node if no content is between them
    result = result.replace(/(<[^\\/>]*)>\n\s*(<[\\/][^>]*>)/g, "$1 />");  //remove \n and join opening with closing tags of the same node to one entire node if no content is between them
    result = result.replace(/(<([a-zA-Z:]+\b)[^>]*>)\n\s*([^<]+)\n\s*(<\/\2>)/g, "$1$3$4"); //remove \n between opening, content and closing tags of the same node (to display in one line)

    return result.trim();
}