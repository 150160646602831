import React, { Component } from 'react';
import { Solutions } from './components/solutions/SolutionInstaller';

import './app.css';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <>
                <Solutions />
            </>
        )
    }
}
