import * as React from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { Modal, PrimaryButton, Stack, IButtonStyles } from '@fluentui/react';
import { WizardInstallList } from './WizardInstallList';
import { WizardActionWarning } from './WizardActionWarning';
import { WizardInstallFailed } from './WizardInstallFailed';
import { WizardAction } from './WizardAction';
import { InstallationStep, WizardSteps } from './WizardSteps';
import { packageService, setup, packageResolver, updateLogService } from '../solutions/SolutionInstaller';
import { IPackage, IPackageVersion, PackageStatus } from '../../typings/Packaging';
import { getModalStyles } from '../../services/utils/Utils';
import { IImportStatus, SolutionImporter } from '../../services/import/SolutionImporter';
import { marked } from 'marked';
import { equalOrGreater, greaterThan } from '../../services/resolver/VersionComparer';
import { IImportJob, ImportType } from '../../services/proxy/IProxy';
import moment from 'moment';

export interface IPackageToInstall {
    version: IPackageVersion;
    currentVersionNumer: string;
    solution: string;
    active: boolean;
    status?: PackageStatus;
    job?: IImportJob;
}
let solutionImporter: SolutionImporter;
export function UpdateWizard(props: { downloadImportLog: (importJobId: string, solutionId: string, solutionVersion: string) => void, packageUpdates: IPackage[], setPackageUpdates: Function, installEnabled: boolean, setInstallEnabled: Function, changeSelected: Function }) {
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

    const [nextBtnText, setNextBtnText] = React.useState('Next');
    const [nextBtnTitle, setNextBtnTitle] = React.useState('Start the installation process');

    const [installationIsActive, { setFalse: deactivateInstall, setTrue: activateInstall }] = useBoolean(false);
    const [finishedInstalling, { setFalse: reset, setTrue: finish }] = useBoolean(false);

    const [packagesToInstall, setPackagesToInstall] = React.useState<(IPackageToInstall[])>([]);

    const [status, setStatus] = React.useState<IImportStatus | null>(null);
    const [importType, setImportType] = React.useState<ImportType>(1);
    
    const [currentStep, setCurrentStep] = React.useState<number>(InstallationStep.Steps);
    const [manualSteps, setManualSteps] = React.useState<JSX.Element[]>([])

    function openWizard() {
        showModal();
        if (installationIsActive) {
            setCurrentStep(InstallationStep.Install);
        }else{
            const steps = getSteps(props.packageUpdates);
            setManualSteps(steps);
        }
    };

    function start() {
        if (!installationIsActive) {
            setNextBtnText('Status');
            setNextBtnTitle('Check the installation status');
            activateInstall();
            setCurrentStep(InstallationStep.Install);
            update();
        }
    };

    function setUnfinishedUpgrade(id: string){
        let packageUpdate = props.packageUpdates.find(x => x.id === id);
        if (packageUpdate) {
            packageUpdate.hasUnfinishedUpgrade = true;
            props.setPackageUpdates(props.packageUpdates);
        }
    }

    async function update() {
        if (!installationIsActive) {
            const selection: IPackageToInstall[] = [];
            for (let i = 0; i < props.packageUpdates.length; i++) {
                const element = props.packageUpdates[i];
                if (element.selected) {
                    const selected: IPackageToInstall = {
                        version: element.selected,
                        currentVersionNumer: element.currentVersionNumber,
                        active: false,
                        status: element.status,
                        solution: ''
                    };
                    selection.push(selected);
                }
            }

            const resolvedPackages = packageResolver.resolveDependencies(selection.slice());
            setPackagesToInstall(resolvedPackages);

            const updateLog = updateLogService.newUpdateLog(resolvedPackages, props.packageUpdates, importType);
            await updateLogService.saveUpdateLog(updateLog);

            showModal();
            activateInstall();

            solutionImporter = new SolutionImporter(setup.proxy, packageService, updateLogService, setPackagesToInstall, changeSelectedToCurrent, setUnfinishedUpgrade);

            var result = await solutionImporter.importSolutions(resolvedPackages, importType, updateLog);
            if(result){
                setStatus(result);
            }else{
                finish();
            }
        }
    }

    function setupInstall(){
        const selection: IPackageToInstall[] = [];
        for (let i = 0; i < props.packageUpdates.length; i++) {
            const element = props.packageUpdates[i];
            console.log("Selected:")
            if (element.selected) {
                console.log(element.selected.id);
                selection.push({
                    version: element.selected,
                    currentVersionNumer: element.currentVersionNumber,
                    active: false,
                    status: element.status,
                    solution: ''
                });
            }
        }

        const resolvedPackages = packageResolver.resolveDependencies(selection.slice());
        setPackagesToInstall(resolvedPackages);
    }

    async function resumeProcess(status: IImportStatus, retryCurrent: boolean) {
        if(retryCurrent){
            status.selection[status.currentIndex].job = undefined;
        }

        setStatus(null);

        var result = await solutionImporter.importSolutions(status.selection, status.importType, status.updateLog);
        if(result){
            setStatus(result);
        }else{
            finish();
        }
    }
    
    function clearInstallation(){
        props.setInstallEnabled(false);
        const updates = props.packageUpdates.slice();
        
        for (let i = 0; i < updates.length; i++) {
            const element = updates[i];
            if(element.selected){
                props.changeSelected(element.selected);
            }
        }
        setStatus(null);
        hideModal();
        deactivateInstall();
        reset();
        setCurrentStep(InstallationStep.Steps);
        setNextBtnText('Next');
        setNextBtnTitle('Start the installation process');
    }
    
    function changeSelectedToCurrent(id: string) {
        let updates = props.packageUpdates.slice();
        if (updates) {
            let packageUpdate = updates.find(x => x.id === id);
            if (packageUpdate) {
                if (packageUpdate.selected?.version) {
                    packageUpdate.currentVersionNumber = packageUpdate.selected?.version;
                }
                if(packageUpdate.status === PackageStatus.New){
                    packageUpdate.status = PackageStatus.Installed;
                    
                }else{
                    packageUpdate.status = PackageStatus.Updated;
                }
                packageUpdate.lastUpdated = moment(Date.now()).format("MMMM Do");
                
                packageUpdate.selected = undefined;
                props.setPackageUpdates(updates);
            }
        }
    }

    const cancelImport = () => {
        if(solutionImporter) {
            solutionImporter.cancelImport();
        }
    }

    return (
        <>
            <Modal
                isOpen={isModalOpen}
                onDismiss={hideModal}
                isBlocking={true}
                containerClassName={modalStyles.container}
            >
                {currentStep === InstallationStep.Steps ? <WizardSteps hideModal={hideModal} setCurrentStep={setCurrentStep} setupInstall={setupInstall} start={start} manualSteps={manualSteps} installEnabled={props.installEnabled} installationIsActive={installationIsActive} packages={props.packageUpdates} /> : ''}
                {currentStep === InstallationStep.Action ? <WizardAction hideModal={hideModal} setCurrentStep={setCurrentStep} setupInstall={setupInstall} installEnabled={props.installEnabled} importType={importType} setImportType={setImportType} /> : ''}
                {currentStep === InstallationStep.Warning ? <WizardActionWarning hideModal={hideModal} setCurrentStep={setCurrentStep} setupInstall={setupInstall} installEnabled={props.installEnabled} /> : ''}
                {currentStep === InstallationStep.Install ? <WizardInstallList cancel={cancelImport} downloadImportLog={props.downloadImportLog} hideModal={hideModal} setCurrentStep={setCurrentStep} startInstalling={start} installEnabled={props.installEnabled} installationIsActive={installationIsActive} installFailed={status !== null} finishedInstalling={finishedInstalling} finish={clearInstallation} packagesToInstall={packagesToInstall} /> : ''}
                {currentStep === InstallationStep.InstallFailed ? <WizardInstallFailed hideModal={hideModal} setCurrentStep={setCurrentStep} packagesToInstall={packagesToInstall} status={status} resumeProcess={resumeProcess} stop={clearInstallation}/> : ''}
            </Modal>
            <Stack horizontal reversed={true}>
                <PrimaryButton disabled={!props.installEnabled} title={nextBtnTitle} text={nextBtnText} onClick={openWizard} allowDisabledFocus />
            </Stack>
        </>
    );
}

// styling for modal
const modalStyles = getModalStyles();
function getSteps(packages: IPackage[]): JSX.Element[] {
    const value: JSX.Element[] = [];
    for (let i = 0; i < packages.length; i++) {
        const current = packages[i];

        if (!current.selected) {
            continue;
        }

        const currentVersion = current.currentVersionNumber;
        const selectedVersion = current.selected.version;

        const getHtml = (notes: string) => {
            var markedUp = marked(notes);
            return { __html: markedUp };
        }

        if (packages[i].installationSteps) {
            value.push(<div key={current.id}>
                <h5>{current.friendlyId}</h5>
                {<div dangerouslySetInnerHTML={getHtml(packages[i].installationSteps!)}></div>}
            </div>);
        }

        current.versions.forEach(version => {
            if (greaterThan(version.version, currentVersion) && 
                equalOrGreater(selectedVersion, version.version) 
                && version.steps) {
                    
                value.push(<div key={current.id + version.version}>
                    <h5>{current.friendlyId} {version.version}</h5>
                    {<div dangerouslySetInnerHTML={getHtml(version.steps)}></div>}
                </div>);

            }
        });
    }

    if (value.length) {
        return value;
    }

    return [<p key={0}>There are no manual steps to perform</p>];
};