import { IPackageToInstall } from '../../components/installation/UpdateWizard';
import { IPackage } from '../../typings/Packaging';
import { equalOrGreater } from './VersionComparer';

export class PackageResolver {
    constructor(private prioritizedSolutions: string[]) {
    }

    // based on Kahn's algorithm: https://en.wikipedia.org/wiki/Topological_sorting
    // returns empty array when starting node can not be found
    resolveDependencies(selectionState: IPackageToInstall[]): IPackageToInstall[] {
        const selection: IPackageToInstall[] = JSON.parse(JSON.stringify(selectionState));

        const resolved: IPackageToInstall[] = [];
        const startingNodes: IPackageToInstall[] = [];

        // find a starting node
        selection.forEach(item => {
            // incoming dependencies means there are packages that depend on this package
            if (!this.hasIncomingDependencies(item, selection)) {
                startingNodes.push(item);
            }
        });

        let pullThePlug = 0;
        while (startingNodes.length > 0 && pullThePlug < 1000) {
            let n = startingNodes.pop();
            if (n) {
                // add to start of array
                const stateItem = selectionState.find(x => x.version.id === n?.version.id)
                if (stateItem) {
                    resolved.unshift(stateItem);
                }

                let dependencies = this.getDependencies(n, selection);

                // remove dependencies (reference to other package) of current node
                let pos = this.findPosition(n.version.id, selection);
                selection[pos].version.dependencies = [];

                // when no other package then n is dependent, 
                for (let i = 0; i < dependencies.length; i++) {
                    if (!this.hasIncomingDependencies(dependencies[i], selection)) {
                        startingNodes.push(dependencies[i]);
                    }
                }
            }

            pullThePlug++
        }

        return this.orderByPriority(resolved);
    }

    orderByPriority(selection: IPackageToInstall[]) {
        /**
         * Priority = [a, b, c]
         * Selection = [d, c, a, b, e]
         * 
         * Looping backwards results in [a, b, c, d, e]
         * Otherwise it results in [d, e, a, b, c]
         */
        for (var i = this.prioritizedSolutions.length - 1; i >= 0; i--) {
            const prioritizedSolution = this.prioritizedSolutions[i];
            const indexOf = selection.findIndex(x => x.version.id === prioritizedSolution);
            if (indexOf > -1) {
                var packageToUnshift = selection.splice(indexOf, 1)[0];
                selection.unshift(packageToUnshift);
            }
        }
        return selection;
    }

    // to see if there are packages who depend on the package to check
    private hasIncomingDependencies(packageToCheck: IPackageToInstall, available: IPackageToInstall[]): boolean {
        let result = false;

        for (let i = 0; i < available.length; i++) {
            const element = available[i];

            for (let j = 0; j < element.version.dependencies.length; j++) {
                const dependency = element.version.dependencies[j];
                if (packageToCheck.version.id === dependency.id) {
                    result = true;
                    break;
                }
            }
        }

        return result;
    }

    // get dependencies of a package as IPackageToInstall (not just a reference)
    private getDependencies(item: IPackageToInstall, available: IPackageToInstall[]): IPackageToInstall[] {
        let dependencies: IPackageToInstall[] = [];
        for (let i = 0; i < available.length; i++) {
            const element = available[i];
            item.version.dependencies.forEach(dep => {
                if (dep.id === element.version.id) {
                    dependencies.push(element);
                }
            });
        }
        return dependencies;
    }

    // find index of package in array. returns -1 if there is no match
    private findPosition(id: string, array: IPackageToInstall[]): number {
        for (let i = 0; i < array.length; i++) {
            if (array[i].version.id === id) {
                return i;
            }
        }
        return -1;
    }

    // sets state of dependency per package to show the user what dependencies are (not) met
    // returns true if all dependencies are met
    checkDependencies(updates: IPackage[]): boolean {
        // used to check if user has selected updates
        let hasSelected = false;

        // when all dependencies are met the user is allowed to install
        let allDependenciesMet = true;

        for (let i = 0; i < updates.length; i++) {
            let selected = updates[i].selected;
            if (selected) {
                hasSelected = true;
                for (let i = 0; i < selected.dependencies.length; i++) {
                    let dependency = selected.dependencies[i];
                    dependency.dependencyMet = false;

                    // check if dependency is met in currently installed solution
                    for (let i = 0; i < updates.length; i++) {
                        let update = updates[i];

                        if (update.id === dependency.id && equalOrGreater(update.currentVersionNumber, dependency.version)) {
                            dependency.dependencyMet = true;
                        }
                    }

                    if (dependency.dependencyMet) {
                        continue;
                    }

                    // check if dependency is met in selected
                    for (let i = 0; i < updates.length; i++) {
                        let selected = updates[i].selected;

                        if (selected?.id === dependency.id && equalOrGreater(selected.version, dependency.version)) {
                            dependency.dependencyMet = true;
                        }
                    }

                    if (!dependency.dependencyMet) {
                        allDependenciesMet = false;
                    }
                }
            }
        }

        if (hasSelected) {
            return allDependenciesMet;
        }

        return false;
    }
}
