import { IPackage } from '../../typings/Packaging';
import { PackageService } from './PackageService'

export class PackageServiceMock extends PackageService {
    async retrieveLicensedSolutions(): Promise<IPackage[]> {
        return [
            {
                id: 'CRMandMore_SolutionInstallerTest1',
                friendlyId: 'CRMandMore Solution Installer Test1',
                currentVersionNumber: '1.0.0.0',
                versions: []
            },
            // {
            //     id: 'CRMandMore_SolutionInstallerTest2',
            //     friendlyId: 'CRMandMore Solution Installer Test2',
            //     currentVersionNumber: '1.0.0.0',
            //     versions: []
            // },
            // {
            //     id: 'CRMandMore_SolutionInstallerTest3_Upgrade',
            //     friendlyId: 'CRMandMore Solution Installer Test3',
            //     currentVersionNumber: '1.0.0.0',
            //     versions: []
            // },
            {
                id: 'CRMandMore_SolutionInstallerTest3',
                friendlyId: 'CRMandMore Solution Installer Test3',
                currentVersionNumber: '1.0.0.0',
                versions: []
            },
            {
                id: 'CRMandMore_SolutionInstallerTest4',
                friendlyId: 'CRMandMore Solution Installer Test4',
                currentVersionNumber: '1.0.0.0',
                versions: []
            }
        ];
    }
}