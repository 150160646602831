import { IPackageService } from '../package/IPackageService';
import { PackageService } from '../package/PackageService';
import { IUpdateLogService } from '../updatelog/IUpdateLogService';
import { UpdateLogService } from '../updatelog/UpdateLogService';
import { Proxy } from '../proxy/Proxy';
import { IProxy } from '../proxy/IProxy';
import { ProxyMock } from '../proxy/ProxyMock';
import { PackageServiceMock } from '../package/PackageServiceMock';
import { sleep } from './Utils';
import { IPackage } from '../../typings/Packaging';

const { REACT_APP_DEV } = process.env;

export class SetupServices{
    private hasFinishedSetup: boolean = false;
    
    proxy: IProxy;
    
    messageHandler: (event: MessageEvent) => any;

    private receivedData: any = {};

    constructor(){
        if (REACT_APP_DEV) {
            this.proxy = new ProxyMock();
            this.hasFinishedSetup = true;
        } else {
            this.proxy = new Proxy('', '');
        }

        this.messageHandler = (event: MessageEvent) => {
            if(this.hasFinishedSetup){
                return;
            }
            this.receivedData = event.data;
            console.log('Received message', event.data);

            if(typeof event.data.requestId === "string"){
                this.proxy = new Proxy(event.data.args[0].organizationId, event.data.args[0].hostname);
                this.hasFinishedSetup = true;
            }
        };
    
        window.self.addEventListener("message", this.messageHandler);
    }

    replaceMessageHandler(handler: (event: MessageEvent) => Promise<void>) {
        this.messageHandler = handler;
    }

    async finishedSetup(){
        while(!this.hasFinishedSetup){
            await sleep(50);
        }
    }

    repost(){
        window.self.postMessage(this.receivedData, window.self.origin);
    }

    CreatePackageService(): IPackageService{
        const data = this.proxy.getAuthorizationData();
        if (REACT_APP_DEV) {
            return new PackageServiceMock(data.organizationId, data.hostname);
        }else{
            return new PackageService(data.organizationId, data.hostname);
        }
    }
        
    CreateUpdateLogService(): IUpdateLogService {
        const data = this.proxy.getAuthorizationData();
        return new UpdateLogService(data.organizationId, data.hostname);
    }

    async connectWithDynamics(packageUpdates: IPackage[] | null, setPackageUpdates: Function, openErrorModal: Function, packageService: IPackageService, updateLogService: IUpdateLogService){
        console.log('New Proxy: Connect with Dynamics');
        const connecting = this.proxy.connect();
        
        this.repost();
        
        console.log('New Proxy: wait for connection');
        await connecting;
        console.log('New Proxy: Connected');
        if (!packageUpdates) {
            const installed = await packageService.retrieveLicensedSolutions();

            const result = await packageService.getPackageUpdates(installed);
            if (result.statusCode === 200) {
                // use logs to show date last updated or installed
                setPackageUpdates(result.value);
                const packages = await updateLogService.setDateLastUpdated(result.value);
                setPackageUpdates(packages);
            } else {
                openErrorModal(result.statusCode);
            }
        }
    }
}