import { IProxy } from '../proxy/IProxy';
import { CreateHeaders } from '../../services/utils/ServiceUtils';
import moment from 'moment';
import { RetrieveRequestType, StoreRequestByAlternateKeyRequest } from '../proxy/ProxyTypes';
const { REACT_APP_SOLUTIONINSTALLERAPI_BASEURL } = process.env;

export interface IRequestLicenseResponse{
    feature: string;
    response: ILicenseResponse;
}
export interface ILicenseResponse {
    licenseStatus: string;
    signature: string;
    expirationDate: string;
    finalExpirationDate: string;
    organizationId: string;
    featureCode: number;
    featureName: string;
    usageQuery: string;
    isTrial: boolean;
}
export interface IFeatureInfo {
    featureCode: number;
    featureName: string;
    licenseStatus: string;
    remainingDays?: number;
    validating?: boolean;
    usage?: number;
}
export interface ILicenseInput {
    featureCode: number;
    signature: string;
    expirationDate: string;
    finalExpirationDate: string;
    usageQuery: string;
    isTrial: boolean;
}

export class LicenseRequestService{
    private _proxy: IProxy;
    private _headers: any;
    private _baseUrl: string;

    constructor(proxy: IProxy) {
        this._proxy = proxy;
        this._headers = CreateHeaders(this._proxy.crmInfo.organizationId, this._proxy.crmInfo.hostName);
        this._baseUrl = REACT_APP_SOLUTIONINSTALLERAPI_BASEURL ? REACT_APP_SOLUTIONINSTALLERAPI_BASEURL : '';
    }

    private async renewLicense(solutionId: string) {
        const url = `${this._baseUrl}/api/requestTrialLicense/${solutionId}`;
        const init: RequestInit = {
            method: 'POST',
            headers: this._headers,
        };

        const response = await fetch(url, init);

        if(response.ok){
            return { ok: true, value: await response.json() }
        }

        return { ok: false, value: 'Automated (trial) license request failed. Request a new license if needed.' }
    }

    async requestTrialLicense(id: string) {
        const response = await this.renewLicense(id);
        if(response.ok){
            try {
                const feature: IFeatureInfo = {
                    featureCode: 0,
                    featureName: "",
                    licenseStatus: "Trial"
                }
                response.value.forEach(async (license: IRequestLicenseResponse) => {
                    feature.licenseStatus = license.response.licenseStatus;
                    feature.featureName = license.feature;

                    license.response.expirationDate = license.response.expirationDate.split("T")[0];
                    license.response.finalExpirationDate = license.response.finalExpirationDate.split("T")[0];

                    this.determineRemainingDaysForLicense(feature, license.response.expirationDate);

                    await this.storeLicense(feature.featureName, license.response);
                });
            } catch (e) {
                console.log('Error requesting license: ', e);
                return false;
            }
            return true;
        }
        return false;
    }

    private determineRemainingDaysForLicense(feature: IFeatureInfo, expirationDate: string) {
        let today = moment().startOf('day'); // set to 12:00 am today
         
        // Expiration date is retrieved as string: yyyy-MM-dd, parse to moment object
        let expDate = moment(expirationDate);

        let durationInDays = expDate.diff(today, 'days');

        console.log(`duration is `, durationInDays, `days`);

        // When negative number of days, the trial is expired, don't show the remaining
        if (durationInDays >= 0) {
            feature.remainingDays = durationInDays;
        }
    }

    private async storeLicense(featureName: string, licenseInput: ILicenseInput) {
        const storeRequest: StoreRequestByAlternateKeyRequest = {
            entitySetName: "cnm_licenses", 
            data: {
                "cnm_featurecode": licenseInput.featureCode,
                "cnm_expirationdate": licenseInput.expirationDate,
                "cnm_name": featureName,
                "statecode": 0, 
                "statuscode": 1,
                "cnm_finalexpirationdate": licenseInput.finalExpirationDate,
                "cnm_signature": licenseInput.signature,
                "cnm_usagequery": licenseInput.usageQuery,
                "cnm_istrial": licenseInput.isTrial
            },
            type: RetrieveRequestType.ByAlternateKey,
            apiVersion: "v9.0",
            keyname: "cnm_featurecode",
            keyvalue: licenseInput.featureCode
        };

        const response = await this._proxy.Invoke("store", storeRequest);

        if(response.error){
            throw new Error(response.error);
        }
    }
}