import * as React from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { Modal, IconButton, IIconProps, IButtonStyles, PrimaryButton, DefaultButton, Stack, List, mergeStyleSets, getTheme, normalize, Icon } from '@fluentui/react';
import { getModalStyles, sleep, createGUID } from '../../services/utils/Utils';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { packageService } from '../solutions/SolutionInstaller';
import { IPackage, IPackageIdentity } from '../../typings/Packaging';
import { validateImport } from '../../services/import/ImportProfileValidator';
import { PacoImportProcess } from './PacoImportProcess';
import { IProxy } from '../../services/proxy/IProxy';
const { REACT_APP_PACO_BASEURL } = process.env;


// modal to show release notes of solution
const pacoSpinner = 'Waiting for PACO...';
const copySpinner = 'URL copied to clipboard. Open the URL in a private browser and select solutions you want to import in this environment.';
const importSpinner = 'Importing...';
let importProcess: PacoImportProcess;
export function PacoImport(props: { proxy: IProxy, packageUpdates: IPackage[] | null, clearSelection: Function, addSolutions: Function, changeSelectedUpdate: Function }) {
    const [colleagueStep, { toggle: toggleColleagueStep }] = useBoolean(true);
    const [isColleague, setIsColleague] = React.useState<boolean>(false);

    const [spinnerTitle, setSpinnerTitle] = React.useState<string>(pacoSpinner);
    const [spinnerIsVisible, { setFalse: hideSpinner, setTrue: showSpinner }] = useBoolean(false);

    const [isModalOpen, { toggle: toggleHideModal }] = useBoolean(false);

    const [identities, setIdentities] = React.useState<IPackageIdentity[] | null>(null);
    const [shopping, { setFalse: finishedShopping, setTrue: startShopping }] = useBoolean(false);
    const [importing, { setFalse: finishedImport, setTrue: startImport }] = useBoolean(false);

    async function fetchFromPACO(inPrivate: boolean = false) {
        startShopping();

        const id = createGUID();
        importProcess = new PacoImportProcess(id, props.proxy);
        let pacoWindow;

        if (inPrivate) {
            copyToClipboard(REACT_APP_PACO_BASEURL + id);
            setSpinnerTitle(copySpinner);
            setTimeout(() => setSpinnerTitle(pacoSpinner), 10000);
            showSpinner();
        } else {
            pacoWindow = window.open(REACT_APP_PACO_BASEURL + id);
            setSpinnerTitle(pacoSpinner);
            showSpinner();
        }
        await sleep(10000);


        let fetchedIdentities: IPackageIdentity[] = [];
        while (importProcess.process.isPending() && fetchedIdentities.length === 0) {
            const result = await packageService.getImportedSolutions(id);

            if (result) {
                fetchedIdentities = result;
            }

            await sleep(5000);
        }

        setIdentities(fetchedIdentities.length > 0 ? fetchedIdentities : null);

        if (pacoWindow) {
            pacoWindow.close();
        }

        hideSpinner();
        finishedShopping();
        setSpinnerTitle(importSpinner);
    }

    async function finishImport() {
        startImport();
        showSpinner();
        const updates = props.clearSelection() as IPackage[];
        const status = validateImport(updates, identities ? identities : [], true);
        status.versions.forEach((version) => {
            props.changeSelectedUpdate(version);
        });
        await props.addSolutions(status.add);
        importProcess.finish(true, identities ? identities : []);
        finishedImport();
        hideSpinner()
        setIdentities(null);
        toggleHideModal();
    }

    function dismissPacoImport() {
        if (importProcess) {
            importProcess.finish(false);
        }
        if (!identities) {
            setSpinnerTitle(pacoSpinner);
            toggleColleagueStep();
        }
    }

    function copyToClipboard(text: string) {
        const listener = function (ev: any) {
            ev.preventDefault();
            ev.clipboardData.setData('text/plain', text);
        };
        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
    }

    const onRenderCell = (item: any, index: number | undefined): JSX.Element => {
        const identity = item as IPackageIdentity;
        return (
            <div className={styles.itemContent}>
                {identity.friendlyId}<span style={{ float: 'right', marginRight: 20 }}>{identity.version}</span>
            </div>
        );
    };
    return (
        <div>
            <DefaultButton title="Select solutions in PACO to import in this environment." text="Import from PACO" onClick={toggleHideModal} allowDisabledFocus styles={{ root: { marginTop: 10 } }} />
            <Modal
                isOpen={isModalOpen}
                onDismissed={dismissPacoImport}
                onDismiss={toggleHideModal}
                isBlocking={true}
                containerClassName={modalStyles.container}
            >
                <div className={modalStyles.header}>
                    <span>Import solutions from PACO</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close"
                        onClick={toggleHideModal}
                    />
                </div>
                <div className={modalStyles.body} style={{ minHeight: 200, maxWidth: 500 }}>
                    {spinnerIsVisible ? <LoadingSpinner title={spinnerTitle} /> : ''}
                    {colleagueStep ? <p>Are you a Fellowmind colleague?</p> :
                        isColleague ?
                            identities ?
                                <div className={styles.container} data-is-scrollable>
                                    <List
                                        items={identities}
                                        onRenderCell={onRenderCell}
                                    />
                                </div>
                                :
                                <>
                                    <p>You can select your solutions from PACO. Click 'Go PACO' to open the PACO store in a new tab.
                                        <br /><br />
                                        If you cannot login with your Fellowmind account in this browser session, you can copy the link to PACO and open the store in another browser session.
                                    </p>
                                    <div className="alert alert-primary" role="alert">
                                        Keep this dialog open while going to PACO, otherwise the connection with PACO will be lost.
                                    </div>
                                </>
                            : <p>Send an email to <a href="mailto:productdevelopment@fellowmind.nl">productdevelopment@fellowmind.nl</a> to request the solution packages.</p>
                    }
                </div>
                <div className={modalStyles.footer}>
                    <Stack horizontal reversed>
                        {colleagueStep ?
                            <>
                                <DefaultButton text="Yes" onClick={() => { setIsColleague(true); toggleColleagueStep() }} />
                                <DefaultButton text="No" onClick={() => { setIsColleague(false); toggleColleagueStep() }} styles={{ root: { marginRight: 10 } }} />
                            </>
                            :
                            isColleague ?
                                identities ?
                                    importing ? '' :
                                        <>
                                            <PrimaryButton disabled={identities === null} text="Import" onClick={finishImport} />
                                            <DefaultButton disabled={identities === null} text="Close" onClick={toggleHideModal} styles={{ root: { marginRight: 10 } }} />
                                        </>
                                    :
                                    shopping ? '' :
                                        <>
                                            <PrimaryButton text="Go PACO" onClick={() => fetchFromPACO(false)} />
                                            <span onClick={() => fetchFromPACO(true)} style={{ marginRight: 25, paddingTop: 5, cursor: 'pointer' }}>Copy link to PACO <Icon iconName="Copy" styles={{ root: { verticalAlign: 'text-top', zIndex: 200000 } }} /></span>
                                            <input type="hidden" value="" id="pacoUrl" />
                                        </>
                                :
                                <DefaultButton text="Back" onClick={dismissPacoImport} />
                        }
                    </Stack>
                </div>
            </Modal>
        </div>
    );
};

// styling for modal
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const modalStyles = getModalStyles();
const iconButtonStyles: IButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginRight: '4px'
    }
};
const theme = getTheme();
const styles = mergeStyleSets({
    container: {
        overflow: 'auto',
        border: '1px solid #CCC',
        marginTop: 20,
        selectors: {
            '.ms-List-cell:nth-child(odd)': {
                height: 25,
                lineHeight: 25,
                background: theme.palette.neutralLighter,
            },
            '.ms-List-cell:nth-child(even)': {
                height: 25,
                lineHeight: 25
            },
        },
    },
    itemContent: [
        theme.fonts.medium,
        normalize,
        {
            position: 'relative',
            boxSizing: 'border-box',
            display: 'block',
            paddingLeft: 20,
        },
    ],
});