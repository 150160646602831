import * as React from 'react';
import {  IIconProps, IButtonStyles, Stack, PrimaryButton, DefaultButton, IconButton } from '@fluentui/react';
import { getModalStyles } from '../../services/utils/Utils';
import { IPackage, PackageStatus } from '../../typings/Packaging';


export enum InstallationStep {
    Steps,
    Action,
    Install,
    InstallFailed,
    Warning
}

// modal to show release notes of solution
export function WizardSteps(props: { hideModal: Function, manualSteps: JSX.Element[], setCurrentStep: Function, setupInstall: Function, start: Function, installEnabled: boolean, installationIsActive: boolean, packages: IPackage[] }) {
    function setNextStep(){
        if(props.packages.findIndex(x => x.selected && x.status !== PackageStatus.New) > -1){
            props.setCurrentStep(InstallationStep.Action)
        }else{
            props.setupInstall();
            props.setCurrentStep(InstallationStep.Install);
        }
    }
    return (
        <>
            <div className={modalStyles.header}>
                <span>Manual Steps</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    onClick={() => props.hideModal()}
                />
            </div>
            <div className={modalStyles.body}>
                {props.manualSteps}
            </div>
            <div className={modalStyles.footer}>
                <Stack horizontal reversed={true}>

                    {!props.installationIsActive ? 
                    <PrimaryButton disabled={!props.installEnabled} text="Next" onClick={() => setNextStep()} allowDisabledFocus styles={buttonStyles} />
                    : 
                    <DefaultButton disabled={!props.installEnabled} text="Status" onClick={() => props.setCurrentStep(InstallationStep.Install)} allowDisabledFocus styles={buttonStyles} />}
                </Stack>
            </div>
        </>
    );
};
const modalStyles = getModalStyles();
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const buttonStyles: IButtonStyles = {
    root: {
        margin: '10px 10px 0 0'
    }
};
const iconButtonStyles: IButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginRight: '4px'
    }
};