import * as React from 'react';
import { IIconProps, IButtonStyles, Stack, PrimaryButton, IconButton, IChoiceGroupOptionProps, IChoiceGroupOption, ChoiceGroup, DefaultButton } from '@fluentui/react';
import { getModalStyles } from '../../services/utils/Utils';
import { InstallationStep } from './WizardSteps';
import { ImportType } from '../../services/proxy/IProxy';

// modal to show release notes of solution
export function WizardAction(props: { hideModal: Function, installEnabled: boolean, importType: ImportType, setImportType: Function, setCurrentStep: Function, setupInstall: Function }) {
    function setupInstall() {
        if (props.importType === ImportType.Update) {
            props.setCurrentStep(InstallationStep.Warning);

        } else {
            props.setupInstall();

            props.setCurrentStep(InstallationStep.Install);
        }
    }
    const options: IChoiceGroupOption[] = [
        {
            key: '1', text: 'Upgrade (recommended)', onRenderLabel: (props) => addLabel(props, 'Upgrade solution to the selected version. Any components not present in the selected solution will be deleted. (Recommended)')
        },
        {
            key: '0', text: 'Update (not recommended)', onRenderLabel: (props) => addLabel(props, 'Replace solution with the selected version. (Not Recommended)')
        },
    ];
    function addLabel(props: IChoiceGroupOption | IChoiceGroupOptionProps | undefined, arg1: string): JSX.Element | null {
        if (props) {
            return <>
                <span style={props.key === '1' ? style : { ...style, opacity: '50%' }}>
                    <span id={props.labelId} className='ms-ChoiceFieldLabel'>
                        <strong>{props.text}</strong>
                    </span>
                    <p style={{ paddingLeft: 26 }}>
                        {arg1}
                    </p>
                </span>
            </>
        }
        return <></>;
    }

    return (
        <>
            <div className={modalStyles.header} style={style}>
                <span>Solution Action</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    onClick={() => props.hideModal()}
                />
            </div>
            <div className={modalStyles.body} style={style}>
                <ChoiceGroup selectedKey={props.importType.toString()} options={options} onChange={(e, d) => props.setImportType(d?.key ? parseInt(d?.key) : 1)} />
            </div>
            <div className={modalStyles.footer} style={style}>
                <Stack horizontal reversed={true}>
                    <PrimaryButton disabled={!props.installEnabled} text="Next" onClick={() => setupInstall()} allowDisabledFocus styles={buttonStyles} />
                    <DefaultButton disabled={!props.installEnabled} text="Back" title="Back to steps" onClick={() => props.setCurrentStep(InstallationStep.Steps)} allowDisabledFocus styles={buttonStyles} />
                </Stack>
            </div>
        </>
    );
};
const style = { maxWidth: 500 }
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const modalStyles = getModalStyles();
const buttonStyles: IButtonStyles = {
    root: {
        margin: '10px 10px 0 0'
    }
};
const iconButtonStyles: IButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginRight: '4px'
    }
};