import React from 'react';
import { DefaultButton, IButtonStyles, IconButton, IIconProps, PrimaryButton, Stack } from '@fluentui/react';
import { InstallationStep } from './WizardSteps';
import { getModalStyles } from '../../services/utils/Utils';

export function WizardActionWarning(props: { hideModal: Function, installEnabled: boolean, setCurrentStep: Function, setupInstall: Function }) {
    function setupInstall() {
        props.setupInstall();

        props.setCurrentStep(InstallationStep.Install);
    }
    return (
        <>
            <div className={modalStyles.header} style={style}>
                <span>Solution Action</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    onClick={() => props.hideModal()}
                />
            </div>
            <div className={modalStyles.body} style={style}>
                <strong>Update (Not Recommended)</strong>
                <p>You opted to update instead of upgrade, we recommend upgrading the solution. If you are sure you want to update, press next. Or go back and choose upgrade.</p>
            </div>
            <div className={modalStyles.footer} style={style}>
                <Stack horizontal reversed={true}>
                    <PrimaryButton disabled={!props.installEnabled} text="Next" onClick={() => setupInstall()} allowDisabledFocus styles={buttonStyles} />
                    <DefaultButton disabled={!props.installEnabled} text="Back" title="Back to choose step" onClick={() => props.setCurrentStep(InstallationStep.Action)} allowDisabledFocus styles={buttonStyles} />
                </Stack>
            </div>
        </>
    );
}

const style = { maxWidth: 500 }
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const modalStyles = getModalStyles();
const buttonStyles: IButtonStyles = {
    root: {
        margin: '10px 10px 0 0'
    }
};
const iconButtonStyles: IButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginRight: '4px'
    }
};