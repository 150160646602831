import * as React from 'react';
import moment from 'moment';
import {
    IColumn, SelectionMode, IListProps, IIconStyles, Stack, DefaultButton, ScrollablePane, ScrollbarVisibility, Sticky,
    StickyPositionType, IRenderFunction, IDetailsHeaderProps, IDetailsColumnRenderTooltipProps, TooltipHost,
    Icon, initializeIcons, registerIcons, ShimmeredDetailsList
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { UpdateDropdown } from '../common/UpdateDrowdown'
import { IPackage, IPackageIdentity, IPackageVersion, PackageStatus } from '../../typings/Packaging';
import { SetupServices } from '../../services/utils/SetupServices';
import { SolutionInfoModal } from './SolutionInfoModal';
import { DependencyList } from '../common/DependencyList';
import { ImportProfileDialog } from './ImportProfileDialog';
import { beautifyXml, downloadFile } from '../../services/utils/Utils';
import { greaterThan } from '../../services/resolver/VersionComparer';
import { PackageResolver } from '../../services/resolver/PackageResolver';
import { IPackageService } from '../../services/package/IPackageService';
import { UpdateLogs } from '../updatelog/UpdateLogs';
import { IUpdateLogService } from '../../services/updatelog/IUpdateLogService';
import { PacoImport } from '../pacoImport/PacoImport';
import { ServerErrorModal } from '../common/ServerErrorModal';
import { UpdateWizard } from '../installation/UpdateWizard';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { RetrieveByIdRequest, RetrieveRequestType } from '../../services/proxy/ProxyTypes';

export let packageResolver: PackageResolver;
export let packageService: IPackageService;
export let updateLogService: IUpdateLogService;
export let setup: SetupServices;
export let appInsights: ApplicationInsights;

// Use the registerIcons api from the styling package to register custom svg icons so that they
// can be used by the Icon component (or in anything that renders Icons, like Buttons).
registerIcons({
    icons: {
        'sipgiftbox': (
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24" style={{ width: 18, height: 18 }}>
                <g>
                    <path fill="#333333" d="M22.4,13.7l-1,1L19.6,13v10.7H3.3V13l-1.7,1.7l-1-1l3.2-3.2h6.4c-0.3,0.3-0.6,0.6-0.9,0.8
		                c-0.3,0.2-0.7,0.5-1.1,0.6H4.8v10.3h13.4V12h-3.8c0.1-0.2,0.3-0.5,0.4-0.7c0.1-0.2,0.2-0.5,0.3-0.7h4.1L22.4,13.7z"/>
                </g>
                <path fill="#FFE900" d="M21.4,6.8c0.2,0.4,0.4,0.8,0.7,1.1c0.3,0.3,0.7,0.6,1.1,0.7c-0.4,0.2-0.8,0.4-1.1,0.7c-0.3,0.3-0.6,0.7-0.7,1.1
	                    C21.2,10,21,9.6,20.7,9.3C20.4,9,20,8.7,19.6,8.6c0.4-0.2,0.8-0.4,1.1-0.7C20.9,7.6,21.2,7.2,21.4,6.8z"/>
                <path fill="#FFE900" d="M3,5.2C2.9,4.8,2.6,4.5,2.3,4.1C2,3.8,1.6,3.6,1.2,3.4C1.6,3.2,2,3,2.3,2.7S2.9,2,3,1.6C3.2,2,3.5,2.3,3.8,2.7
	                    C4.1,3,4.5,3.2,4.9,3.4C4.5,3.6,4.1,3.8,3.8,4.1C3.5,4.5,3.2,4.8,3,5.2z"/>
                <path fill="#FF9900" d="M19,2.7c-0.6-0.6-1.1-1.4-1.5-2.2c-0.4,0.8-0.9,1.6-1.5,2.2s-1.4,1.1-2.2,1.5c0.8,0.4,1.6,0.9,2.2,1.5
	                    c0.6,0.6,1.1,1.3,1.5,2.2C17.9,7,18.4,6.3,19,5.7s1.4-1.1,2.2-1.5C20.4,3.8,19.6,3.3,19,2.7z M17.5,5.6c-0.5-0.5-1-1-1.5-1.4
	                    c0.6-0.4,1.1-0.9,1.5-1.4c0.4,0.5,0.9,1,1.5,1.4C18.4,4.6,17.9,5.1,17.5,5.6z"/>
                <path fill="#00AB4E" d="M9.4,8.8c-0.4,0-0.7,0-1-0.2C8,8.5,7.8,8.3,7.5,8C7.3,7.8,7.1,7.5,7,7.1c-0.1-0.3-0.2-0.7-0.2-1
	                    c0-0.5,0.1-0.9,0.3-1.3s0.4-0.7,0.8-1C8.3,3.5,8.6,3.3,9,3.1s0.8-0.3,1.3-0.3c0.7,0,1.2,0.1,1.7,0.4s0.9,0.6,1.3,1.1
	                    c0.3,0.4,0.6,1,0.8,1.5c0.2,0.6,0.3,1.1,0.3,1.7c0,0.8-0.1,1.6-0.4,2.3c-0.3,0.7-0.6,1.3-1.1,1.9s-1,1.1-1.6,1.5s-1.3,0.8-2,1.1
	                    c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.3,0.1-0.5,0.4-0.6c0.6-0.3,1.1-0.6,1.6-0.9
	                    c0.5-0.3,0.9-0.7,1.3-1.1c0.4-0.4,0.7-0.9,0.9-1.4s0.4-1.1,0.5-1.8c0-0.1,0-0.2,0-0.2s0-0.1,0-0.2c0-0.4-0.1-0.8-0.2-1.2
	                    c-0.1-0.4-0.3-0.8-0.5-1.1c-0.2-0.3-0.5-0.6-0.9-0.8C11.1,4.1,10.6,4,10.2,4C9.9,4.1,9.7,4.2,9.4,4.3S8.9,4.5,8.7,4.7
	                    C8.5,4.9,8.4,5.1,8.2,5.3C8.1,5.6,8,5.8,8,6.1c0,0.2,0,0.4,0.1,0.5C8.2,6.8,8.3,7,8.4,7.1c0.1,0.1,0.3,0.2,0.4,0.3
	                    C9,7.5,9.2,7.6,9.4,7.6s0.3,0,0.4-0.1c0.1-0.1,0.2-0.1,0.3-0.2s0.1-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.3s0.1-0.2,0.2-0.2
	                    c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,0.3,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5v0.1c-0.1,0.2-0.2,0.4-0.3,0.6S11,8.2,10.8,8.3
	                    s-0.4,0.3-0.7,0.4C9.9,8.8,9.6,8.8,9.4,8.8z"/>
            </svg>
        ),
    },
});

export function Solutions() {
    initializeIcons();

    // modal for showing release notes
    const [packageForModal, setPackageForModal] = React.useState<IPackage | null>(null)
    const [isModalOpen, { setFalse: hideInfoModal, setTrue: showInfoModal }] = useBoolean(false);

    const [isLogPanelOpen, { setFalse: closeLogPanel, setTrue: openLogPanel }] = useBoolean(false);

    const [installEnabled, setInstallEnabled] = React.useState(false);

    // list of packages
    const [packageUpdates, setPackageUpdates] = React.useState<(IPackage[] | null)>(null);

    const [isErrorModalOpen, { setFalse: hideErrorModal, setTrue: showErrorModal }] = useBoolean(false);
    const [errorCode, setErrorCode] = React.useState<number>(0);
    function openErrorModal(code: number) {
        setErrorCode(code);
        showErrorModal();
    }

    async function setupServices() {
        setup = new SetupServices();
        await setup.finishedSetup();
        packageService = setup.CreatePackageService();
        updateLogService = setup.CreateUpdateLogService();

        // connection with dynamics
        setup.connectWithDynamics(packageUpdates, setPackageUpdates, openErrorModal, packageService, updateLogService).then(() => {
            trackPageView();
        });

        // package resolver is used to determine which solutions should be installed first
        // based on priority list and dependencies
        packageService.getPriorityList().then(result => {
            packageResolver = new PackageResolver(result);
        });
    }

    if (!setup) {
        setupServices();
    }

    // used to update the list of packages to install
    function changeSelectedUpdate(selectedUpdate: IPackageVersion) {
        let enableInstall: boolean = false;
        if (packageUpdates) {
            let updates = packageUpdates.slice();

            // update table
            for (let i = 0; i < updates.length; i++) {
                if (updates[i].id === selectedUpdate.id) {
                    if (selectedUpdate.version === '0') {
                        updates[i].selected = undefined;
                    } else {
                        updates[i].selected = selectedUpdate;
                    }
                }
            }

            // returns true if all dependencies are met 
            enableInstall = packageResolver.checkDependencies(updates ? updates : []);

            setPackageUpdates(updates);
        }

        // enable or disable 'installeren' button
        setInstallEnabled(enableInstall);
    }

    async function addSolutions(solutions: IPackage[]) {
        const result = await packageService.getPackageUpdates(solutions);

        const packages = packageUpdates ? packageUpdates.slice() : [];
        if (result.statusCode === 200) {
            for (let i = 0; i < result.value.length; i++) {
                const element = result.value[i] as IPackage;
                // current version does not exist for some reason
                if (!element.currentVersion) {
                    continue;
                }
                element.status = PackageStatus.New;
                element.selected = element.currentVersion;
                if (element.currentVersion) {
                    element.versions.unshift(element.currentVersion);
                }
                element.currentVersionNumber = '0.0.0.0';

                const index = packages.findIndex(x => x.id === element.id);
                if (index === -1) {
                    packages.unshift(element);
                } else {
                    packages[index] = element;
                }
            }
            const enableInstall = packageResolver.checkDependencies(packages ? packages : []);
            setPackageUpdates(packages);
            setInstallEnabled(enableInstall);

        } else {
            openErrorModal(result.statusCode);
        }
    }

    function removeSolution(id: string) {
        if (packageUpdates) {
            const copy = packageUpdates.slice();
            const toRemove = copy.findIndex(x => x.id === id);
            copy.splice(toRemove, 1);
            setPackageUpdates(copy);

            const enableInstall = packageResolver.checkDependencies(packageUpdates ? packageUpdates : []);
            setInstallEnabled(enableInstall);
        }
    }

    function selectLatestVersion() {
        if (packageUpdates) {
            for (let i = 0; i < packageUpdates.length; i++) {
                if (packageUpdates[i].hasUnfinishedUpgrade) {
                    continue;
                }
                if (packageUpdates[i].versions.length > 0) {
                    var max = packageUpdates[i].versions.reduce(function (a, b) {
                        return greaterThan(a.version, b.version) ? a : b;
                    });

                    if (packageUpdates[i].currentVersionNumber === max.version || packageUpdates[i].status === PackageStatus.New) {
                        continue;
                    }
                    changeSelectedUpdate(max);
                }
            }
        }
    }

    function clearSelection() {
        let updates: IPackage[] = [];
        if (packageUpdates) {
            updates = packageUpdates.slice();
            for (let i = 0; i < updates.length; i++) {
                if (updates[i].status === PackageStatus.New) {
                    updates.splice(i, 1);
                    i--;
                    continue;
                }
                updates[i].selected = undefined;
            }

            const enableInstall = packageResolver.checkDependencies(updates ? updates : []);

            setPackageUpdates(updates);
            setInstallEnabled(enableInstall);
        }
        setInstallEnabled(false);
        return updates;
    }

    // to show information about a package and it's updates (release notes, manual steps)
    async function showInfo(update: IPackage) {
        setPackageForModal(null);
        showInfoModal();
        const pack = await packageService.getPackage(update.id, update.currentVersionNumber);
        pack.friendlyId = update.friendlyId;
        setPackageForModal(pack);
    }

    // download current versions as file
    function exportSolutionVersionConfig() {
        const solutionsToExport: IPackageIdentity[] = [];
        if (packageUpdates) {
            for (let i = 0; i < packageUpdates.length; i++) {
                solutionsToExport.push({
                    id: packageUpdates[i].id,
                    friendlyId: packageUpdates[i].friendlyId,
                    version: packageUpdates[i].currentVersionNumber,
                });
            }

            let date = new Date();
            let today = `${date.getUTCDate()}${date.getMonth() + 1}${date.getFullYear()}`;
            downloadFile(JSON.stringify({ solutions: solutionsToExport }, null, 2), `solutionexport_${setup.proxy.getAuthorizationData().hostname}_${today}.json`);
        }
    }

    // data to show in column
    const onRenderItemColumn = (item: any, index: number | undefined, column: IColumn | undefined): JSX.Element | string | number | undefined => {
        const solution = item as IPackage;

        if (column?.key === 'info') {
            return <Icon iconName="InfoSolid" title="Click to view release notes" onClick={() => showInfo(item)} styles={infoIconStyles} />;
        }

        if (column?.key === 'current') {
            if (solution.currentVersionNumber === "0.0.0.0") {
                return <>New Solution <Icon iconName="Delete" styles={removeIconStyles} onClick={() => removeSolution(item.id)} /></>;
            }
            if (solution.status === PackageStatus.Updated) {
                return <>{solution.currentVersionNumber} (Updated {solution.lastUpdated})</>;
            }
            if (solution.status === PackageStatus.Installed) {
                return <>{solution.currentVersionNumber} (Installed {solution.lastUpdated})</>;
            }
        }


        if (column?.key === 'versions') {
            if (solution.hasUnfinishedUpgrade) {
                return <TooltipHost content="There is a pending upgrade of this solution. Navigate to dynamics Advanced Settings. Under Customization you will find Solutions and Solutions History.
                    Revolve the issue shown in Solutions History and finish the upgrade in the Solutions settings.">
                    <span>Upgrade pending<Icon
                        iconName="WarningSolid"
                        styles={warningIconStyles} /></span>
                </TooltipHost>
            }

            let versions = solution.versions as IPackageVersion[];
            let hasUpdates = versions.filter(x => greaterThan(x.version, solution.currentVersionNumber)).length;
            if (hasUpdates) {
                const mostRecent = versions[versions.length - 1];
                const releaseDate = moment(mostRecent.releaseDate, "YYYY-MM-DD");
                const lastWeek = moment().subtract(21);

                const daysAgo = lastWeek.diff(releaseDate, 'days');
                if (daysAgo <= 7) {
                    const title = daysAgo === 0 ? `Version ${mostRecent.version} released today` : `Version ${mostRecent.version} recently released (${releaseDate.format("MMMM Do")})`;
                    return <><UpdateDropdown installedPackage={item} onChange={changeSelectedUpdate} />
                        <Icon iconName="sipgiftbox" styles={recentReleaseIconStyles} title={title} /></>;
                }

                return <UpdateDropdown installedPackage={item} onChange={changeSelectedUpdate} />;
            }

            return <>Up-to-date</>;
        }

        if (column?.key === 'dependencies') {
            if (solution.selected?.dependencies?.length) {
                return <DependencyList dependencies={solution.selected.dependencies} />;
            }

            return '-';
        }

        return item[column?.fieldName as keyof IPackage];
    };

    async function downloadImportLog(importJobId: string, solutionId: string, solutionVersion: string) {
        const retrieveFormattedResults: RetrieveByIdRequest = {
            id: `ImportJobId=${importJobId}`,
            type: RetrieveRequestType.ById,
            entitySetName: 'RetrieveFormattedImportJobResults',
            apiVersion: "v9.0"
        };

        const response = await setup.proxy.Invoke("executeQuery", retrieveFormattedResults);
        const xmlString = beautifyXml(response.FormattedResults, "\t");

        downloadFile(xmlString, `ImportJob_${solutionId}_${solutionVersion}_${setup.proxy.getAuthorizationData().hostname}.xml`, "application/xml;charset=utf-8");
    }

    return (
        <>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <Sticky stickyPosition={StickyPositionType.Header}>
                    <div style={{ padding: '20px 30px 0 30px' }}>
                        <Stack horizontal horizontalAlign="space-between">
                            <Stack horizontalAlign="start">
                                <h2 style={h2Style}>Solution Dashboard</h2>
                            </Stack>
                            <Stack horizontal horizontalAlign="end">
                                <DefaultButton type="a" reversed={false} text="Update History" iconProps={{ iconName: 'FullHistory' }} title="Navigate to log of finished updates." onClick={openLogPanel} />
                            </Stack>
                        </Stack>
                        <Stack horizontal horizontalAlign="space-between">
                            <Stack horizontal horizontalAlign="start">
                                <DefaultButton title={exportButtonText} iconProps={{ iconName: 'Export' }} text="Export Profile" onClick={exportSolutionVersionConfig} allowDisabledFocus styles={{ root: { marginRight: 10, marginTop: 10 } }} />
                                <ImportProfileDialog proxy={setup.proxy} packageUpdates={packageUpdates} clearSelection={clearSelection} addSolutions={addSolutions} changeSelectedUpdate={changeSelectedUpdate} />
                                <PacoImport proxy={setup.proxy} packageUpdates={packageUpdates} clearSelection={clearSelection} addSolutions={addSolutions} changeSelectedUpdate={changeSelectedUpdate} />
                            </Stack>
                        </Stack>
                    </div>
                </Sticky>
                <ShimmeredDetailsList
                    setKey="packages"
                    items={packageUpdates || []}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    onRenderItemColumn={onRenderItemColumn}
                    onRenderDetailsHeader={onRenderDetailsHeader}
                    enableShimmer={!packageUpdates}
                    ariaLabelForShimmer="Updates are being fetched"
                    ariaLabelForGrid="Update details"
                    listProps={shimmeredDetailsListProps} />
                <Sticky stickyPosition={StickyPositionType.Footer}>
                    <Stack style={{ padding: '10px 20px 30px 20px' }} horizontal reversed={true} tokens={{childrenGap: 10 }}>
                        <SolutionInfoModal packageForModal={packageForModal} isModalOpen={isModalOpen} hideModal={hideInfoModal} />
                        <UpdateWizard downloadImportLog={downloadImportLog} packageUpdates={packageUpdates ? packageUpdates : []} installEnabled={installEnabled} changeSelected={changeSelectedUpdate} setInstallEnabled={setInstallEnabled} setPackageUpdates={setPackageUpdates} />
                        <DefaultButton title="Select latest available version of every installed solutions" text="Select latest" onClick={selectLatestVersion} allowDisabledFocus />
                        <DefaultButton title="Deselect updates and remove new solutions." text="Reset" onClick={clearSelection} allowDisabledFocus />
                    </Stack>
                </Sticky>
            </ScrollablePane>
            <UpdateLogs downloadImportLog={downloadImportLog} isPanelOpen={isLogPanelOpen} closePanel={closeLogPanel} />
            <ServerErrorModal statusCode={errorCode} hideModal={hideErrorModal} isModalOpen={isErrorModalOpen} />
        </>
    );
}
async function trackPageView() {
    appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: process.env.REACT_APP_INSTRUMENTATIONKEY
        }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView({
        name: "Solution Installer Portal",
        properties: {
            "organizationid": setup.proxy.getAuthorizationData().organizationId,
        }
    });
}
const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
    if (!props) {
        return null;
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
        <TooltipHost {...tooltipHostProps} />
    );
    return (
        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
            {defaultRender!({
                ...props,
                onRenderColumnHeaderTooltip,
            })}
        </Sticky>
    );
};
const h2Style: React.CSSProperties = {
    marginBottom: 40,
    fontSize: 30,
    fontWeight: 500,
    lineHeight: 1.1,
    color: 'inherit',
    fontFamily: '"Helvetica Neue", Helvetica',
};
const exportButtonText = "Export a solution profile, describing the solution versions that are installed in this environment. You can import this profile into another environment to align the installed Fellowmind solutions.";
const infoIconStyles: Partial<IIconStyles> = {
    root: { cursor: 'pointer', color: '#0078d4', fontSize: 'medium', position: 'absolute', top: 9, right: 5 }
};
const warningIconStyles: Partial<IIconStyles> = {
    root: { cursor: 'pointer', fontSize: 'medium', position: 'absolute', top: 8, right: 0, color: '#ffcc00' }
};
const removeIconStyles: Partial<IIconStyles> = {
    root: { cursor: 'pointer', fontSize: 'medium', color: '#d13438', position: 'absolute', left: 85, top: 9 }
};
const recentReleaseIconStyles: Partial<IIconStyles> = {
    root: { cursor: 'pointer', fontSize: 'medium', position: 'absolute', right: 0, top: 5 }
};
const columns: IColumn[] = [
    { key: "id", name: "Solution", fieldName: "friendlyId", minWidth: 200 },
    { key: "current", name: "Current version", fieldName: "currentVersionNumber", minWidth: 200 },
    { key: "info", name: "", fieldName: "", minWidth: 25 },
    { key: "versions", name: "Available versions", fieldName: "versions", minWidth: 170 },
    { key: "dependencies", name: "Dependencies", fieldName: "dependencies", minWidth: 250 }
];
const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0
};