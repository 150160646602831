import * as React from 'react';
import { IPackage } from '../../typings/Packaging';
import { IconButton, IIconProps, IButtonStyles, Modal } from '@fluentui/react';
import { marked } from 'marked';
import { getModalStyles } from '../../services/utils/Utils';

export function Steps(props: { packageUpdates: IPackage[], isDialogOpen: boolean, hideDialog: any}){
    function getSteps(){
        let value: JSX.Element[] = [];
        for (let i = 0; i < props.packageUpdates.length; i++) {
            const current = props.packageUpdates[i];
    
            const getHtml = (notes: string) => {
                var markedUp = marked(notes);
                return { __html: markedUp };
            }
    
            current.versions.forEach(version => {
                if (version.steps)
                    value.push(<div key={current.id+version.version}>
                                <h5>{current.id} {version.version}</h5>
                                {<div dangerouslySetInnerHTML={getHtml(version.steps)}></div>}
                            </div>);
            });
        }
    
        if(value.length){
            return value;
        }
    
        return [<p key={1}>There are no manual steps.</p>];
    }

    return (
        <>
            <Modal
                isOpen={props.isDialogOpen}
                onDismiss={props.hideDialog}
                containerClassName={contentStyles.container}
            >
                <div className={contentStyles.header}>
                    <span>Manual steps</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        onClick={props.hideDialog}
                    />
                </div>
                <div className={contentStyles.body}>
                    {getSteps()}
                </div>
            </Modal>
        </>
    );
}

// styling for modal
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const contentStyles = getModalStyles();
const iconButtonStyles: IButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginRight: '4px'
    }
};