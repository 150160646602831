import { IPackageToInstall } from '../../components/installation/UpdateWizard';
import { IPackage, PackageStatus } from '../../typings/Packaging';
import { IUpdateLog } from '../../typings/UpdateLog';
import { IUpdateLogService } from './IUpdateLogService';
import { createGUID } from '../utils/Utils';
import { CreateHeaders, fetchRetry, IHeaders } from '../utils/ServiceUtils';
import { ImportType } from '../proxy/IProxy';
import moment from 'moment';

const { REACT_APP_SOLUTIONINSTALLERAPI_BASEURL } = process.env;

export class UpdateLogService implements IUpdateLogService {
    private _logs: IUpdateLog[] | null;
    private _headers: IHeaders;
    private _baseUrl: string;

    constructor(organizationId: string, hostname: string) {
        this._logs = null;
        this._headers = CreateHeaders(organizationId, hostname);
        this._baseUrl = REACT_APP_SOLUTIONINSTALLERAPI_BASEURL ? REACT_APP_SOLUTIONINSTALLERAPI_BASEURL : '';
    }

    newUpdateLog(orderedPackagesToInstall: IPackageToInstall[], packageUpdates: IPackage[], importType: ImportType): IUpdateLog {
        let log: IUpdateLog = {
            logId: createGUID(),
            hostname: this._headers.HostName,
            importType: importType,
            items: []
        };

        orderedPackagesToInstall.forEach((element) => {
            let current = packageUpdates.find(x => x.id === element.version.id)?.currentVersionNumber;
            log.items.push({
                solutionId: element.version.id,
                updatedVersion: element.version.version,
                previousVersion: current ? current : '0.0.0.0',
            })
        });

        return log;
    }

    async getUpdateLog(logId: string, hostname: string): Promise<IUpdateLog | null> {
        if (this._logs === null) {
            await this.getUpdateLogs(hostname);
        }

        if (this._logs) {
            let log = this._logs.find(x => x.logId === logId);
            return log ? log : null;
        }

        return null;
    }

    async getUpdateLogs(hostname: string): Promise<IUpdateLog[]> {
        if(this._logs === null){
            const url = `${this._baseUrl}/api/log?hostname=${hostname}`;
            const init = {
                method: 'GET',
                headers: this._headers
            };
    
            let response = await fetchRetry(url, init);
    
            const logs = await response.json() as IUpdateLog[];
    
            this._logs = logs;
        }

        return this._logs;
    }

    async saveUpdateLog(updateLog: IUpdateLog): Promise<boolean> {
        const url = `${this._baseUrl}/api/log`;
        const init = {
            method: 'POST',
            headers: this._headers,
            body: JSON.stringify(updateLog)
        };

        const response = await fetchRetry(url, init);
        if (response.ok) {
            await this.addLogToArray(updateLog);

            return true;
        }

        return false;
    }

    async setDateLastUpdated(packageUpdates: IPackage[]): Promise<IPackage[]> {
        const updatedSolution: string[] = [];
        const updateLogs = await this.getUpdateLogs(this._headers.HostName); 
        const logs = updateLogs.filter((log) => moment(Date.now()).diff(moment(log.timestamp, "DD-MM-YYYY"), 'days') <= 2);
        if(logs){
            for (let i = 0; i < logs.length; i++) {
                const log = logs[i];
                log.items.forEach((logItem) => {
                    var pack = packageUpdates.find(x => !updatedSolution.includes(x.id) && x.id === logItem.solutionId && logItem.successfullyInstalled);
                    if(pack){
                        updatedSolution.unshift(pack.id);
                        pack.status = logItem.previousVersion === "0.0.0.0" ? PackageStatus.Installed : PackageStatus.Updated;
                        if(log.timestamp){
                            pack.lastUpdated = moment(log.timestamp, "DD-MM-YYYY").format("MMMM Do");
                        }
                    }
                });
            }
        }
        return packageUpdates;
    }

    // we only want to get the logs 1 time, so we should update the array client side
    private async addLogToArray(updateLog: IUpdateLog) {
        if(this._logs === null){
            await this.getUpdateLogs(updateLog.hostname);
            return;
        }

        let logToOverwrite = await this.getUpdateLog(updateLog.logId, updateLog.hostname);
        if(logToOverwrite){
            logToOverwrite.items = updateLog.items;
            return;
        }

        const d = new Date();
        const timestamp = `${this.formatted(d.getUTCDate())}-${this.formatted(d.getMonth() + 1)}-${d.getUTCFullYear()} ${this.formatted(d.getUTCHours())}:${this.formatted(d.getUTCMinutes())}:${this.formatted(d.getUTCSeconds())}`;
        updateLog.timestamp = timestamp;
        if(this._logs){
            this._logs.unshift(updateLog);
        }
    }

    private formatted(value: number){
        return ('0'+ value).slice(-2);
    }
}

