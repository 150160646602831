import * as React from 'react';
import { IconButton, IIconProps, IButtonStyles, PrimaryButton, DefaultButton, Stack, Icon, IIconStyles } from '@fluentui/react';
import { getModalStyles } from '../../services/utils/Utils';
import { IImportStatus } from '../../services/import/SolutionImporter';
import { IPackageToInstall } from './UpdateWizard';
import { InstallationStep } from './WizardSteps';
import { greaterThan } from '../../services/resolver/VersionComparer';

// modal to show release notes of solution
export function WizardInstallFailed(props: { hideModal: Function, setCurrentStep: Function, packagesToInstall: IPackageToInstall[], status: IImportStatus | null, resumeProcess: Function, stop: Function }) {
    function skip(){
        props.setCurrentStep(InstallationStep.Install);
        props.resumeProcess(props.status);
    }

    function retry(){
        props.setCurrentStep(InstallationStep.Install);
        props.resumeProcess(props.status, true);
    }

    function stop(){
        props.setCurrentStep(InstallationStep.Install);
        props.stop();
    }

    function getFailedSolution(){
        const failed = props.status?.selection[props.status.currentIndex].version.friendlyId;
        return failed;
    }

    function canContinue(){
        const selectionLength = props.status?.selection.length ? props.status?.selection.length : 0;
        const currentIndex = props.status?.currentIndex ? props.status.currentIndex : 0;
        if(currentIndex + 1 === selectionLength ){
            return false;
        }

        return true;
    }

    function hasNoSolutionsDepending(){
        let index = props.status?.currentIndex ? props.status?.currentIndex + 1 : 1;
        const failed = props.status?.selection[props.status?.currentIndex];
        const toInstall = props.status?.selection;
        if(failed && toInstall){
            for (index; index < toInstall.length; index++) {
                const next = toInstall[index];
                for (let i = 0; i < next.version.dependencies.length; i++) {
                    const dependency = next.version.dependencies[i];
                    if(dependency.id === failed.version.id && greaterThan(dependency.version, failed.currentVersionNumer)){
                        return false;
                    }
                }
            }
        }

        return true;
    }

    return (
        <>
            <div className={modalStyles.header} style={style}>
                <span>To continue or not to continue</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    onClick={() => props.hideModal()}
                />
            </div>
            <div className={modalStyles.body} style={style}>
                    <p>
                        An error occurred while installing {getFailedSolution()}. Check the Solution History in CRM for additional information. 
                    </p>
                    {canContinue() ? 
                         hasNoSolutionsDepending() ? 
                            <p> 
                                Fix the error and click on 'Retry' to retry failed solution, choose 'Skip' to install the next solution(s) or choose 'Stop' to finish the process.
                            </p>
                        :
                            <>
                            <div className="alert-info alert">
                                <p>
                                    <Icon iconName="InfoSolid" title="Solutions are dependent on failed solution" styles={infoIconStyles}/> 
                                    Can't skip this solution, because other solutions are dependent on this one.
                                </p>
                            </div>
                            <p> 
                                Fix the error and click on 'Retry' to retry failed solution or choose 'Stop' to finish the process.
                            </p>
                            </>
                        :
                        <p> 
                            Fix the error and click on 'Retry' to retry failed solution or choose 'Stop' to finish the process.
                        </p>
                    }
            </div>
            <div className={modalStyles.footer} style={style}>
                <Stack horizontal horizontalAlign="space-between">
                    <Stack horizontal horizontalAlign="start" >
                        <DefaultButton text="Back" title="Show Solution" onClick={() => props.setCurrentStep(InstallationStep.Install)} allowDisabledFocus styles={buttonStyles} />
                    </Stack>
                    <Stack horizontal horizontalAlign="end">
                        <DefaultButton text="Stop" onClick={stop} allowDisabledFocus styles={buttonStyles} />
                        {canContinue() && hasNoSolutionsDepending() ?
                        <PrimaryButton text="Skip" title="Skip installing the next solution(s)" onClick={skip} allowDisabledFocus styles={buttonStyles} />
                        : ''}
                        <PrimaryButton text="Retry" title="Retry installing from failed solution" onClick={retry} allowDisabledFocus styles={buttonStyles} />
                    </Stack>
                </Stack>
            </div>
        </>
    );
};

// styling for modal
const style = { maxWidth: 500 }
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const modalStyles = getModalStyles();
const iconButtonStyles: IButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginRight: '4px'
    }
};
const buttonStyles: IButtonStyles = {
    root: {
        margin: '10px 10px 0 0'
    }
};
const infoIconStyles: Partial<IIconStyles> = {
    root: { verticalAlign: 'text-top', marginRight: 5 }
};