import React from 'react'
import { List, mergeStyleSets, getTheme, normalize, ProgressIndicator, IIconProps, IButtonStyles, IconButton, PrimaryButton, Stack, DefaultButton } from '@fluentui/react';
import { IPackageToInstall } from './UpdateWizard';
import { JobResultModal } from './JobResultModal';
import { getModalStyles } from '../../services/utils/Utils';
import { InstallationStep } from './WizardSteps';
import { PackageStatus } from '../../typings/Packaging';
import { useBoolean } from '@fluentui/react-hooks';

const theme = getTheme();
export function WizardInstallList(props: { cancel: () => void, downloadImportLog: (importJobId: string, solutionId: string, solutionVersion: string) => void, hideModal: Function, installationIsActive: boolean, setCurrentStep: Function, startInstalling: Function, installEnabled: boolean, installFailed: boolean, finishedInstalling: boolean, finish: Function, packagesToInstall: IPackageToInstall[] }) {
    const [cancelInitiated, { toggle: toggleCancel }] = useBoolean(false);

    function getLabel(solution: IPackageToInstall): string {
        if (solution.job) {
            const rounded = Math.round(solution.job.progress);
            return `${rounded}%`;
        }

        return '0%';
    }
    const onRenderCell = (item: any, index: number | undefined): JSX.Element => {
        const solution = item as IPackageToInstall;
        const style = {
            borderLeft: '3px solid' + determineColor(solution)
        };

        const i = index ? index : 0;
        const progressTrackColor = i % 2 === 0 ? 'white' : '#F3F2F1';
        return (
            <div data-is-focusable>
                <div className={styles.itemContent} style={style}>
                    {solution.version.friendlyId} {solution.version.version}

                    {solution.active === true ?
                        <span>
                            <label style={{ position: 'absolute', right: 5, fontSize: 12 }}>
                                {getLabel(solution)}
                            </label>
                            <ProgressIndicator
                                barHeight={20}
                                styles={{
                                    root: {
                                        display: 'inline-block',
                                        position: 'absolute',
                                        top: -1,
                                        right: 35,
                                        width: 75
                                    },
                                    progressTrack: {
                                        background: progressTrackColor
                                    }
                                }}
                                percentComplete={solution.job ? solution.job.progress / 100 : 0} />
                        </span>
                        : ''}

                    {!solution.active && solution.job !== undefined ?
                        <JobResultModal downloadImportLog={props.downloadImportLog} importJob={item.job} canceled={item.status === PackageStatus.Canceled} />
                        : ''}
                </div>
            </div>
        );
    };

    function setPreviousStep() {
        if (props.packagesToInstall.findIndex(x => x.status !== PackageStatus.New) > -1) {
            props.setCurrentStep(InstallationStep.Action);
        } else {
            props.setCurrentStep(InstallationStep.Steps);
        }
    }

    function isCancelDisabled() {
        if (props.packagesToInstall.findIndex(x => x.status === PackageStatus.Canceled) > -1) {
            return true;
        }

        if (props.packagesToInstall.findIndex(x => !x.job) === -1) {
            return true;
        }

        return false;
    }

    return (
        <>
            <div className={modalStyles.header}>
                <span>Installing Solutions</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    onClick={() => props.hideModal()}
                />
            </div>
            <div className={modalStyles.body}>
                {cancelInitiated ?
                    <p>You cannot cancel the current import. Do you want to cancel the next imports?</p>
                    :
                    <div className={styles.container}>
                        <List
                            items={props.packagesToInstall}
                            onRenderCell={onRenderCell}
                        />
                    </div>}
            </div>
            <div className={modalStyles.footer}>
                <Stack reversed horizontal>
                    {cancelInitiated ?
                        <>
                            <DefaultButton text="Yes" onClick={() => { props.cancel(); toggleCancel() }} allowDisabledFocus styles={buttonStyles} />
                            <DefaultButton text="No" onClick={toggleCancel} allowDisabledFocus styles={buttonStyles} />
                        </>
                        :
                        props.installationIsActive ?
                            <>
                                {props.installFailed ?
                                    <PrimaryButton disabled={!props.installFailed} text="Next" onClick={() => props.setCurrentStep(InstallationStep.InstallFailed)} allowDisabledFocus styles={buttonStyles} />
                                    :
                                    <>
                                        <PrimaryButton disabled={!props.finishedInstalling} text="Finish" onClick={() => props.finish()} allowDisabledFocus styles={buttonStyles} />
                                        <DefaultButton disabled={isCancelDisabled()} text="Cancel" onClick={toggleCancel} styles={buttonStyles} />
                                    </>
                                }
                                <DefaultButton title="Navigate back to manual steps." text="Manual steps" onClick={() => props.setCurrentStep(InstallationStep.Steps)} allowDisabledFocus styles={buttonStyles} />
                            </>
                            :
                            <>
                                <PrimaryButton text="Start" onClick={() => props.startInstalling()} allowDisabledFocus styles={buttonStyles} />
                                <DefaultButton text="Back" onClick={() => setPreviousStep()} allowDisabledFocus styles={buttonStyles} />
                            </>}
                </Stack>
            </div>
        </>
    )
}

function determineColor(packageToInstall: IPackageToInstall) {
    if (packageToInstall.status === PackageStatus.Canceled) {
        return theme.palette.neutralLight;
    }
    if (packageToInstall.active || packageToInstall.job?.success === undefined) {
        return theme.palette.themePrimary;
    } else if (packageToInstall.job?.success === undefined) {
        return 'white';
    }

    if (packageToInstall.job?.success) {
        return theme.palette.greenLight;
    }

    return theme.palette.red;
}

const styles = mergeStyleSets({
    container: {
        overflow: 'auto',
        border: '1px solid #CCC',
        marginTop: 20,
        selectors: {
            '.ms-List-cell:nth-child(odd)': {
                height: 25,
                lineHeight: 25,
                background: theme.palette.neutralLighter,
            },
            '.ms-List-cell:nth-child(even)': {
                height: 25,
                lineHeight: 25
            },
        },
    },
    itemContent: [
        theme.fonts.medium,
        normalize,
        {
            position: 'relative',
            boxSizing: 'border-box',
            display: 'block',
            paddingLeft: 20,
        },
    ],
});
const modalStyles = getModalStyles();
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const buttonStyles: IButtonStyles = {
    root: {
        margin: '10px 10px 0 0'
    }
};
const iconButtonStyles: IButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginRight: '4px'
    }
};