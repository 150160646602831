import { IProxy, generateRandomString, IDispatchedCache, ICrmInfo, IDispatched } from './IProxy';

export class Proxy implements IProxy {
    crmInfo: ICrmInfo = {
        hostName: '',
        organizationId: '',
        proxyVersion: '0.0',
    };
    targetWindow: Window;

    private readonly pendingRequests: IDispatchedCache = {};
    private windowMessageHandler: (event: MessageEvent) => Promise<void>;

    constructor(organizationId: string, hostName: string) {
        this.crmInfo.organizationId = organizationId;
        this.crmInfo.hostName = hostName;
        this.targetWindow = window.self.parent;
        this.pendingRequests = {};
        this.windowMessageHandler = (event: MessageEvent) => this.messageHandler(event);
    }

    getAuthorizationData(): { organizationId: string; hostname: string; } {
        return { organizationId: this.crmInfo.organizationId, hostname: this.crmInfo.hostName };
    }

    async connect() {
        const dispatched = this.createDispatched();
        this.pendingRequests['helloSip'] = dispatched;

        this.start();

        const request = await dispatched.promise;

        this.crmInfo.hostName = request.args[0].hostname;
        this.crmInfo.organizationId = request.args[0].organizationId;

        delete this.pendingRequests['helloSip'];
        this.targetWindow.postMessage({ replyId: request.requestId, payload: 'Welcome to the Solution Installer Portal' }, '*');
    }

    private start() {
        window.addEventListener("message", (event: MessageEvent) => this.windowMessageHandler(event));
    }

    Invoke(method: string, ...args: any[]): Promise<any> {
        const requestId = generateRandomString();
        const dispatched = this.createDispatched();
        this.pendingRequests[requestId] = dispatched;

        this.targetWindow.postMessage({ requestId: requestId, name: method, args: args }, '*');

        return dispatched.promise;
    }

    private async messageHandler(event: any) {
        if (typeof event.data.requestId === "string") {
            this.pendingRequests['helloSip'].resolve(event.data);
        } else if (typeof event.data.replyId === "string") {
            const reply = event.data;
            const dispatched = this.pendingRequests[reply.replyId];

            if (dispatched) {
                if (reply.error) {
                    dispatched.reject(reply.error);
                } else {
                    dispatched.resolve(reply.payload);
                }
                delete this.pendingRequests[reply.replyId];
            }
        }
    }

    private createDispatched(): IDispatched {
        let deferred: any;

        const promise = new Promise(function (resolve, reject) {
            deferred = {
                'resolve': resolve,
                'reject': reject,
                'promise': null
            }
        });

        deferred.promise = promise;

        return deferred;
    }
}