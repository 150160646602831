import * as React from 'react';
import { IconButton, IIconProps, Stack, PrimaryButton, IButtonStyles, Modal, ILinkProps, DefaultButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { formatXML, getModalStyles } from '../../services/utils/Utils';
import { IImportJob } from '../../services/proxy/IProxy';
import { WarningFormatter } from '../../services/import/WarningFormatter';

export function JobResultModal(props: { downloadImportLog: (importJobId: string, solutionId: string, solutionVersion: string) => void, importJob: IImportJob, canceled: boolean }) {
    const [isDialogOpen, { setTrue: showModal, setFalse: hideDialog }] = useBoolean(false);
    const [warnings, setWarnings] = React.useState<JSX.Element[] | null>(null);

    async function downloadImportLog() {
        props.downloadImportLog(props.importJob.id ? props.importJob.id : '', props.importJob.solutionName, props.importJob.version);
    }

    // warnings can only occur when an import is finished and successfull
    if (props.importJob.trialRequestComplete !== undefined && warnings === null) {
        const trialRequestComplete = props.importJob.trialRequestComplete === undefined ? true : props.importJob.trialRequestComplete;
        var result = WarningFormatter.formatImportResult(trialRequestComplete, props.importJob.data);
        if (result.length) {
            setWarnings(result);
        }
    }

    return (
        <div>
            {props.importJob.success && props.importJob.trialRequestComplete !== undefined && !warnings ?
                <span key={1} style={linkStyle} onClick={() => downloadImportLog()}>
                    Download log
                </span> : ''}

            {props.importJob.success && props.importJob.trialRequestComplete !== undefined && warnings ?
                <span key={1} style={linkStyle} onClick={showModal}>
                    Warning
                </span> : ''}

            {!props.importJob.success ?
                <span style={linkStyle} onClick={showModal}>
                    Error
                </span> : ''}
            
            {props.canceled ?
                <span style={canceledStyle} >
                    Canceled
                </span> : ''}

            <Modal
                isOpen={isDialogOpen}
                onDismiss={hideDialog}
                isBlocking={false}
                containerClassName={contentStyles.container}
            >
                <div className={contentStyles.header}>
                    <span>{props.importJob.success ? 'Solution Installed' : 'Error updating solution'}</span>
                    <IconButton
                        styles={iconButtonStyles}
                        onClick={hideDialog}
                        iconProps={cancelIcon}
                    />
                </div>
                <div className={contentStyles.body}>
                    {props.importJob.success ?
                        <div style={{ maxHeight: 300, overflow: 'auto' }}>
                            {warnings ? warnings : 'No warnings found'}
                        </div>
                        :
                        <div>
                            <label style={{ width: '100%', margin: 0 }}><strong>Error message:</strong></label>
                            {formatXML(props.importJob.errorText ? props.importJob.errorText : 'No error message available.')}
                        </div>}
                </div>
                <div className={contentStyles.footer}>
                    <Stack horizontal reversed={true}>
                        {props.importJob.id?.length === 36 ? <PrimaryButton text="Download log" onClick={downloadImportLog} style={{marginLeft: 10}} /> : ''}
                        <DefaultButton text="Close" onClick={hideDialog} />
                    </Stack>
                </div>
            </Modal>
        </div>
    );
};

// styling for modal
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const contentStyles = getModalStyles();
const iconButtonStyles: IButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginRight: '4px'
    }
};
const canceledStyle: React.CSSProperties = {
    display: 'inline-block',
    right: 5,
    top: 0,
    position: 'absolute',
    fontSize: 12
}
const linkStyle: ILinkProps = {
    display: 'inline-block',
    right: 5,
    top: 0,
    cursor: 'pointer',
    color: '#0078D4',
    textDecoration: 'underline',
    position: 'absolute',
    fontSize: 12
};
