import { IPackageService } from '../package/IPackageService';
import { PackageServiceMock } from '../package/PackageServiceMock';
import { sleep } from '../utils/Utils';
import { ICrmInfo, IProxy } from './IProxy';
const { REACT_APP_ORGANIZATIONID } = process.env;

var currentProgress: number = 0;
export class ProxyMock implements IProxy{
    crmInfo: ICrmInfo;
    packageServiceMock: IPackageService;

    constructor(){
        this.crmInfo = {
            hostName: 'localhost',
            organizationId: REACT_APP_ORGANIZATIONID ? REACT_APP_ORGANIZATIONID : '',
            proxyVersion: '1.0'
        }
        this.packageServiceMock = new PackageServiceMock(this.crmInfo.organizationId, this.crmInfo.hostName);
    }

    async Invoke(method: string, ...args: any[]): Promise<any> {
        let payload: any = {};
        if(method === "executeQuery"){
            if(args[0].entitySetName === "importjobs"){
                payload = {
                    progress: currentProgress, 
                    data: `<importexportxml data="${currentProgress === 100 ? 'Finished' : 'In Progress'}" processed="${currentProgress >= 100}" success="${currentProgress >= 100}"></importexportxml>`
                }
                currentProgress += 25;

            }else if(args[0].entitySetName === "solutions"){
                payload = this.packageServiceMock.retrieveLicensedSolutions();

            }else if(args[0].entitySetName === "RetrieveFormattedImportJobResults"){
                payload = {FormattedResults: '<result>This is a job result.</result>'}
            }

        }else if(method === "invokeAction"){
            currentProgress = 25;
            while(currentProgress < 100){
                await sleep(2500);
            }
        }

        return payload;
    }
    
    async connect(): Promise<void> {
        console.log('Connected');
    };

    getAuthorizationData(): { organizationId: string; hostname: string; } {
        return { organizationId: this.crmInfo.organizationId, hostname: this.crmInfo.hostName };
    }
}