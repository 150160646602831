export interface IPackage{
    id: string;
    friendlyId: string;
    currentVersionNumber: string;
    versions: IPackageVersion[];
    selected?: IPackageVersion;
    hasUnfinishedUpgrade?: boolean;
    currentVersion?: IPackageVersion;
    status?: PackageStatus;
    lastUpdated?: string;
    installationSteps?: string;
}
export enum PackageStatus{
    New,
    Installed,
    Updated,
    Failed,
    Canceled,
}
export interface IPackageIdentity{
    id: string;
    friendlyId: string;
    version: string;
}

export interface IPackageVersion{
    id: string;
    friendlyId: string;
    version: string;
    dependencies: IPackageDependency[];
    releaseDate: string;
    releaseNotes?: string;
    steps?: string;
}

export interface IPackageDependency{
    id: string;
    version: string;
    optional: boolean;
    dependencyMet?: boolean;
}
