import * as React from 'react';
import { DefaultButton, PrimaryButton, Modal, IconButton, IIconProps, IButtonStyles, initializeIcons, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { IPackage, IPackageIdentity } from '../../typings/Packaging';
import { getModalStyles } from '../../services/utils/Utils';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { validateImport } from '../../services/import/ImportProfileValidator';
import { Link } from 'react-router-dom';
import { appInsights } from './SolutionInstaller';
import { ICustomProperties, IEventTelemetry } from '@microsoft/applicationinsights-web';
import { IProxy } from '../../services/proxy/IProxy';

// modal to show release notes of solution
export function ImportProfileDialog(props: { proxy: IProxy, packageUpdates: IPackage[] | null, clearSelection: Function, addSolutions: Function, changeSelectedUpdate: Function }) {
    const [disabled, { setFalse: enable, setTrue: disable }] = useBoolean(true);
    const [hideModal, { toggle: toggleHideModal }] = useBoolean(true);
    const [importing, setImporting] = React.useState<boolean>(false);
    const [error, setError] = React.useState<JSX.Element | null>();
    const [errorVisible, { setTrue: showError, setFalse: hideError }] = useBoolean(false);
    const [fileContent, setFileContent] = React.useState<IPackageIdentity[] | null>(null);
    const [fileText, setFileText] = React.useState<string>('No solution profile selected');

    function blockImport(message: string) {
        let fileInput = document.getElementById("file") as any;
        if (fileInput) {
            fileInput.value = null;
        }
        disable();
        setImporting(false);
        if (message.length) {
            setErrorElement([message]);
        } else {
            setErrorElement([]);
        }
    };

    function onDismissed() {
        blockImport('');
        setFileText('No solution profile selected');
        hideError();
    }


    function changeFile(e: any) {
        if (e.target.files.length) {
            const file = e.target.files[0];
            setFileText(file.name);
            if (!file.name.endsWith(".json")) {
                return blockImport("File must be json");
            }

            const reader = new FileReader();

            reader.readAsText(file);
            reader.onerror = function () {
                return blockImport("Something went wrong file reading file:\n" + reader.error);
            };
            reader.onload = function () {
                if (reader.result) {
                    const parsedContent = JSON.parse(reader.result.toString());
                    if (parsedContent.solutions) {
                        const identities = parsedContent.solutions as IPackageIdentity[];
                        if (validateContent(identities)) {
                            setFileContent(identities);
                            setErrorElement([]);
                            const status = validateImport(props.packageUpdates ? props.packageUpdates : [], identities, false)
                            if (status.error && status.error.length) {
                                setErrorElement(status.error);
                            }
                            enable();
                        }
                    } else {
                        return blockImport("No solutions found!");
                    }
                } else {
                    return blockImport("File is empty!")
                }
            };
        }
    }

    function setErrorElement(error: string[]) {
        if (error.length) {
            const element = <p key={0} className="errorText">
                {error.map((e, index) => {
                    return <label key={index}>{error[index]}</label>;
                })}
            </p>;
            setError(element);
        } else {
            setError(null);
        }
    }

    function validateContent(identities: IPackageIdentity[]) {
        for (let i = 0; i < identities.length; i++) {
            const identity = identities[i];

            if (!identity.id) {
                setErrorElement(["File corrupt, identity not found."]);
                return false;
            } else if (identity.version.length === 0) {
                setErrorElement([`File corrupt, package with identity: ${identity.id} has no version.`]);
                return false;
            }
        }

        return true;
    }

    function trackImport(solutions: IPackageIdentity[]) {
        const event: IEventTelemetry = {
            name: 'OTAP Import'
        };
        const customProperties: ICustomProperties = {
            organizationId: props.proxy.getAuthorizationData().organizationId,
            solutions: solutions.map(x => `${x.id} ${x.version}`).join(',')
        };
        appInsights.trackEvent(event, customProperties)
    }

    async function startImport() {
        if (fileContent) {
            const updates = props.clearSelection() as IPackage[];
            const status = validateImport(updates, fileContent, true);
            setImporting(true);
            status.versions.forEach((version) => {
                props.changeSelectedUpdate(version);
            });
            await props.addSolutions(status.add);
            trackImport(fileContent);
            toggleHideModal();
            setFileContent(null);
            blockImport('');
        } else {
            setErrorElement(["Please select a file first."]);
        }
    }

    return (
        <div>
            <DefaultButton
                title="Import a solution profile that you exported from another environment. This way you can easily align the installed Fellowmind solutions across environments. Note: you can also use the solution profile import to install Fellowmind solutions in a new environment."
                iconProps={{ iconName: 'Import' }}
                text="Import Profile"
                onClick={toggleHideModal}
                allowDisabledFocus
                styles={{ root: { marginRight: 10, marginTop: 10 } }}
            />
            <Modal
                isOpen={!hideModal}
                onDismissed={onDismissed}
                onDismiss={toggleHideModal}
                isBlocking={false}
                styles={{ main: { maxWidth: 650 } }}
            >
                <div className={contentStyles.header}>
                    <span>Import Solution Profile</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Sluit"
                        onClick={toggleHideModal}
                    />
                </div>
                <div className={contentStyles.body}>
                    {!hideModal && importing ?
                        <LoadingSpinner title="Importing..." />
                        : ''
                    }
                    <div style={{ padding: 20 }}>
                        <p>Import a solution profile that you exported from another environment, to ensure that the same Fellowmind solution versions will be installed in this environment.
                            <br /><br />
                            If a solution already exists, this will select the right version to upgrade to. If not, this will prepare for new installation.
                            <br /><br />
                            <strong>Note:</strong> Importing the solution profile does not start the actual installation/upgrade, it only prepares the solution overview.
                        </p>
                        <div style={{ paddingTop: 20 }}>
                            <div style={{ maxHeight: 200, overflowY: 'auto', marginBottom: 10 }}>
                                {error ?
                                    <>
                                        {errorVisible ? <span>{error}</span> : <label onClick={() => showError()}>Warnings found click <Link to="#">here</Link> to expand.</label>}
                                    </>
                                    :
                                    <label></label>
                                }
                            </div>
                            <DefaultButton iconProps={{ iconName: 'FileRequest' }} text="Select Profile" onClick={() => document.getElementById("file")?.click()} />
                            <label style={{ marginLeft: 10 }}>{fileText}</label>
                            <input style={{ display: 'none' }} id="file" type="file" onChange={changeFile} />
                        </div>
                    </div>
                </div>
                <div className={contentStyles.footer}>
                    <Stack horizontal reversed={true}>
                        <PrimaryButton text="Import Profile" onClick={startImport} disabled={disabled} />
                    </Stack>
                </div>
            </Modal>
        </div>
    );
};
initializeIcons();
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const contentStyles = getModalStyles();
const iconButtonStyles: IButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginRight: '4px',
    }
};