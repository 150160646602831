import { IPackageToInstall } from "../../components/installation/UpdateWizard";
import { PackageStatus } from "../../typings/Packaging";
import { IUpdateLog } from "../../typings/UpdateLog";
import { IPackageService } from "../package/IPackageService";
import { IImportJob, ImportType, IProxy } from "../proxy/IProxy";
import { IUpdateLogService } from "../updatelog/IUpdateLogService";
import { ImportProcess } from "./ImportProcess";

import { createGUID, sleep } from "../utils/Utils";
import { LicenseRequestService } from "./LicenseRequestService";
import { InvokeActionRequest, RetrieveByIdRequest, RetrieveRequestType } from "../proxy/ProxyTypes";
import { appInsights } from "../../components/solutions/SolutionInstaller";
import { WarningFormatter } from "./WarningFormatter";
import { IEventTelemetry, IExceptionTelemetry } from "@microsoft/applicationinsights-common";
import { ICustomProperties } from "@microsoft/applicationinsights-core-js";

let packagesToInstall: IPackageToInstall[] = [];
export class SolutionImporter {
    private _proxy: IProxy;
    private _logService: IUpdateLogService;
    private _packageService: IPackageService;
    private _licenseRequestService: LicenseRequestService;
    private _setPackagesToInstall: Function;
    private _changeCurrentPackage: Function;
    private _setUnfinishedUpgrade: Function;

    constructor(proxy: IProxy, packageService: IPackageService, logService: IUpdateLogService, setPackagesToInstall: Function, changeCurrentPackage: Function, setUnfinishedUpgrade: Function) {
        this._proxy = proxy;
        this._logService = logService;
        this._packageService = packageService;
        this._setPackagesToInstall = setPackagesToInstall;
        this._changeCurrentPackage = changeCurrentPackage;
        this._setUnfinishedUpgrade = setUnfinishedUpgrade;
        this._licenseRequestService = new LicenseRequestService(proxy);
    }

    handleImportUpdate = (importJob: IImportJob) => {
        for (let i = 0; i < packagesToInstall.length; i++) {
            const element = packagesToInstall[i];
            if (element.version.id === importJob.solutionName) {
                element.job = importJob;
            }
        }

        this._setPackagesToInstall(packagesToInstall.slice());
    }

    async importSolutions(selection: IPackageToInstall[], selectedImportType: ImportType, updateLog: IUpdateLog) {
        packagesToInstall = selection;

        // used as an aid to restart import after failure
        var status: IImportStatus = {
            selection: selection,
            importType: selectedImportType,
            updateLog: updateLog,
            currentIndex: 0
        };

        console.log("Importing solutions")

        for (let i = 0; i < packagesToInstall.length; i++) {
            status.currentIndex = i;

            if (packagesToInstall[i].job || packagesToInstall[i].status === PackageStatus.Canceled) {
                continue;
            }

            const importType = packagesToInstall[i].status === PackageStatus.New ? ImportType.Update : selectedImportType;
            const currentLogItem = updateLog.items.find(x => x.solutionId === packagesToInstall[i].version.id);

            // update selection to show active installment
            packagesToInstall[i].active = true;
            this._setPackagesToInstall(packagesToInstall.slice());

            const result = await this._packageService.downloadSolution(packagesToInstall[i].version.id, packagesToInstall[i].version.version);

            if (result.ok) {
                packagesToInstall[i].solution = result.content;

                this.startImport(importType, packagesToInstall[i]);

                // wait till import is finished
                while (!packagesToInstall[i].job || packagesToInstall[i].job?.active) {
                    await sleep(1000);
                }

                // show install result and hide progressbar
                packagesToInstall[i].active = false;
                this._setPackagesToInstall(packagesToInstall.slice());

                if (packagesToInstall[i].job?.success) {
                    // request trial license for newly installed solution
                    const requestLicenseComplete = await this.requestLicense(packagesToInstall[i].version.id, packagesToInstall[i].status);

                    if (currentLogItem) {
                        currentLogItem.importJobId = packagesToInstall[i].job?.id;
                        currentLogItem.successfullyInstalled = true;
                        currentLogItem.errorMessage = WarningFormatter.formatImportResultAsString(requestLicenseComplete, packagesToInstall[i].job?.data);
                        this._logService.saveUpdateLog(updateLog);
                        this._changeCurrentPackage(packagesToInstall[i].version.id);
                    }

                    this.trackImport(importType, packagesToInstall[i]);
                } else {
                    // process must be discontinued when an import fails
                    const errorMessage = packagesToInstall[i].job?.errorText;
                    if (currentLogItem) {
                        currentLogItem.importJobId = packagesToInstall[i].job?.id;
                        currentLogItem.errorMessage = errorMessage;
                        currentLogItem.successfullyInstalled = false;
                        this._logService.saveUpdateLog(updateLog);
                    }
                    // 0x8004f046 => Uninstall for Stage and Upgrade failed
                    if (packagesToInstall[i].job?.errorCode === '0x8004f046') {
                        this._setUnfinishedUpgrade(packagesToInstall[i].version.id)
                    }

                    this.trackImportError(importType, packagesToInstall[i], errorMessage ? errorMessage : "");
                    return status;
                }

            } else {
                // no solution found, import process can't continue 
                packagesToInstall[i].job = {
                    solutionName: packagesToInstall[i].version.id,
                    version: packagesToInstall[i].version.version,
                    importType: importType,
                    errorText: 'Solution file could not be found.',
                    progress: 100,
                    active: false,
                    success: false
                };
                packagesToInstall[i].active = false;
                this._setPackagesToInstall(packagesToInstall.slice());
                if (currentLogItem) {
                    currentLogItem.successfullyInstalled = false;
                    currentLogItem.errorMessage = packagesToInstall[i]?.job?.errorText;
                }
                this._logService.saveUpdateLog(updateLog);
                this.trackImportError(importType, packagesToInstall[i], result.content);
                return status;
            }
        }
    }

    cancelImport() {
        const index = packagesToInstall.findIndex(x => x.active);
        const cancelFromIndex = index === -1 ? 0 : index + 1;
        for (let i = cancelFromIndex; i < packagesToInstall.length; i++) {
            packagesToInstall[i].status = PackageStatus.Canceled;
        }
        this._setPackagesToInstall(packagesToInstall.slice());
    }

    async requestLicense(id: string, status: PackageStatus | undefined): Promise<boolean> {
        let completed = true;

        if (status === PackageStatus.New) {
            completed = await this._licenseRequestService.requestTrialLicense(id);
        }

        for (let i = 0; i < packagesToInstall.length; i++) {
            const element = packagesToInstall[i];
            if (element.version.id === id) {
                if (element.job) {
                    element.job.trialRequestComplete = completed;
                }
            }
        }
        this._setPackagesToInstall(packagesToInstall.slice());

        return completed;
    }

    private async startImport(importType: ImportType, selected: IPackageToInstall) {
        const guid = createGUID();
        let actionName: string;
        let body: string;
        if (importType === ImportType.Update) {
            actionName = 'ImportSolution';
            body = JSON.stringify({
                HoldingSolution: false,
                OverwriteUnmanagedCustomizations: false,
                PublishWorkflows: true,
                CustomizationFile: selected.solution,
                ImportJobId: guid
            });
        } else {
            actionName = 'StageAndUpgrade';
            body = JSON.stringify({
                OverwriteUnmanagedCustomizations: false,
                PublishWorkflows: true,
                CustomizationFile: selected.solution,
                ImportJobId: guid
            });
        }

        const action: InvokeActionRequest = {
            actionName: actionName,
            data: body,
            apiVersion: 'v9.0',
            isBound: true,
            entitySetName: actionName
        };

        const job: IImportJob = {
            id: guid,
            solutionName: selected.version.id,
            version: selected.version.version,
            importType: importType,
            progress: 0,
            active: true,
            success: false
        };

        const importRequestProcess = new ImportProcess();
        const fetchImport = async (retries: number = 3, backoff: number = 300) => {
            await this._proxy.Invoke('invokeAction', action)
                .then(response => {
                    if (!response.error) {
                        importRequestProcess.finish(true);
                    } else {
                        importRequestProcess.finish(false);
                        job.errorCode = response.error.code;
                        job.errorText = response.error.message;
                    }
                }).catch((e) => {
                    if (retries > 0) {
                        console.log("Exception on solution import, retrying. Error: " + e.message);
                        fetchImport(retries - 1, backoff * 2);
                    }
                    job.errorText = "Can't reach API used to import solutions.";
                    importRequestProcess.finish(false);
                });
        };

        fetchImport();

        await this.pollJobStatus(job, importRequestProcess);

        job.active = false;
        job.success = importRequestProcess.isSuccess();
        this.handleImportUpdate(job);
    }

    // check status of job untill finished
    private async pollJobStatus(job: IImportJob, process: ImportProcess) {
        const retrieveImportJob: RetrieveByIdRequest = {
            id: job.id ? job.id : '',
            type: RetrieveRequestType.ById,
            entitySetName: 'importjobs',
            apiVersion: "v9.0"
        };

        let sleepTime = 3000;
        while (process.isPending()) {
            await this.sleepWhilePending(process, sleepTime);

            const response = await this._proxy.Invoke('executeQuery', retrieveImportJob);

            if (!response.error) {
                const formatted = this.formatImportJobRequest(response);

                job.progress = response.progress;
                job.data = response.data;
                job.success = formatted.succeeded;

                this.handleImportUpdate(job);
            }
            else{
                console.log("Error while fetching solution import progress: " + response.error.message);
            }
        }
    }

    // sets timeout and also checks if the import process is still pending
    // the import process could be finished while the program still waits for a timeout based on input
    // this could mean the program has to wait unnecessarily
    private async sleepWhilePending(process: ImportProcess, milliseconds: number) {
        let deferred: any;

        const promise = new Promise(function (resolve) {
            deferred = {
                'resolve': resolve
            }
        });

        deferred.promise = promise;

        setTimeout(() => {
            if (deferred) {
                deferred.resolve();
                deferred = null;
            }
        }, milliseconds);

        // if deferred is null, the timeout has finished
        // if process is still pending, wait for timeout
        while (deferred && process.isPending()) {
            await sleep(1000);
        }

        if (deferred) {
            deferred.resolve();
            deferred = null;
        }

        return promise;
    }

    private formatImportJobRequest(result: any) {
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(result.data, "text/xml");

        let attributes = xmlDoc.getElementsByTagName("importexportxml")[0].attributes;
        let value = {
            processed: false,
            succeeded: false,
            status: ''
        };

        for (let i = 0; i < attributes.length; i++) {
            switch (attributes[i].nodeName) {
                case "processed":
                    value.processed = attributes[i].nodeValue === 'false' ? false : true;
                    break;
                case "succeeded":
                    value.succeeded = attributes[i].nodeValue || '' in ['succeeded', 'success'] ? true : false;
                    break;
                case "status":
                    value.status = attributes[i].nodeValue || '';
                    break;
                default:
                    break;
            }
        }

        return value;
    }

    private trackImportError(importType: ImportType, selected: IPackageToInstall, message: string) {
        const exception: IExceptionTelemetry = {
            error: {
                name: 'Solution Import Failed',
                message: message
            }
        };
        const customProperties: ICustomProperties = {
            organizationId: this._proxy.getAuthorizationData().organizationId,
            solution: `${selected.version.id} ${selected.version.version}`,
            importType: ImportType.Update === importType ? 'Update' : 'Upgrade',
            importJobId: selected.job?.id
        };
        appInsights.trackException(exception, customProperties);
    }

    private trackImport(importType: ImportType, selected: IPackageToInstall) {
        const event: IEventTelemetry = {
            name: 'Solution Import Success'
        };
        const customProperties: ICustomProperties = {
            organizationId: this._proxy.getAuthorizationData().organizationId,
            solution: `${selected.version.id} ${selected.version.version}`,
            importType: ImportType.Update === importType ? 'Update' : 'Upgrade',
            importJobId: selected.job?.id
        };
        appInsights.trackEvent(event, customProperties)
    }

}
export interface IImportStatus {
    selection: IPackageToInstall[];
    importType: ImportType;
    updateLog: IUpdateLog;
    currentIndex: number;
}