import * as React from 'react';
import { Modal, IconButton, IIconProps, IButtonStyles } from '@fluentui/react';
import { formatXML, getModalStyles } from '../../services/utils/Utils';
import { IUpdateLogItem } from '../../typings/UpdateLog';
import { WarningFormatter } from '../../services/import/WarningFormatter';

// modal to show release notes of solution
export function ErrorModal(props: { logItem: IUpdateLogItem | undefined, isModalOpen: boolean, hideModal: any }) {
    function getWarning(): JSX.Element[]{
        if(props.logItem){
            if(props.logItem.successfullyInstalled){
                if(props.logItem.errorMessage){
                    return WarningFormatter.parseString(props.logItem.errorMessage);
                }
            }else if(props.logItem.errorMessage){
                const result = formatXML(props.logItem.errorMessage);
                if(result.length){
                    return result;
                }
            }
        }
        return [<p key={1}>No message available</p>];
    }

    return (
        <div>
            <Modal
                isOpen={props.isModalOpen}
                onDismiss={props.hideModal}
                isBlocking={false}
                containerClassName={modalStyles.container}
            >
                <div className={modalStyles.header}>
                    <span>Import {props.logItem?.successfullyInstalled ? 'Warning' : 'Error'}</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Sluit"
                        onClick={props.hideModal}
                    />
                </div>
                <div className={modalStyles.body}>
                    {getWarning()}
                </div>
            </Modal>
        </div>
    );
};

// styling for modal
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const modalStyles = getModalStyles();
const iconButtonStyles: IButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginRight: '4px'
    }
};