import React from 'react';
import { IPackageDependency } from '../../typings/Packaging';

export const DependencyList = (props: {dependencies: IPackageDependency[] | null}) => {
    const getColor = (met: boolean | undefined) => {
        switch (met) {
            case true:
                return 'green';
            case false:
                return 'red';
            default:
                return 'black';
        }
    }

    return (
      <>
          {props.dependencies?.map((dependency) => (
                <li key={dependency.id+dependency.version}>
                    <span style={{color: getColor(dependency.dependencyMet)}}>{dependency.id} {dependency.version}</span>
                </li>
          ))}
      </>
    );
  };