import * as React from 'react';

interface IWarningItem {
    id: string | undefined;
    name: string | undefined;
    processed: string | undefined;
    result: string | undefined;
    errorText: string | undefined;
}
const compactStyle = {
    margin: 0,
    padding: 0,
    width: '100%'
}
export class WarningFormatter{
    static formatImportResultAsString(requestLicenseComplete: boolean, data: string | undefined): string | undefined {
        const result = this.formatImportResult(requestLicenseComplete, data);
        return this.formatToString(result);
    }

    static formatImportResult(requestLicenseComplete: boolean, data: string | undefined): JSX.Element[] {
        if(requestLicenseComplete){
            return this.format(data);
        }else{
            const warnings = this.format(data);
            warnings.push(
                <div style={{ paddingBottom: 5 }} key="requestlicense-div">
                    <p key="requestlicense-title" style={compactStyle}><strong key="requestlicense-title-strong">Requesting trial license</strong></p>
                    <p key="requestlicense-body" style={compactStyle}>Automated request of trial license failed. Request a new license if needed.</p>
                </div>
            );
            return warnings;
        }
    }

    static parseString(jsxElementAsString: string): JSX.Element[] {
        return JSON.parse(jsxElementAsString, (k, v) => {
            const matches = v && v.match && v.match(/^\$\$Symbol:(.*)$/);
            
            return matches ? Symbol.for(matches[1]) : v;
        });
    }

    public static formatToString(formatted: JSX.Element[]): string | undefined {
        if (formatted.length) {
            return JSON.stringify(formatted, (key, value) => {
                if (["_owner", "_store", "_self", "_source"].includes(key)) {
                    return null;
                }
                // https://www.thomasmaximini.com/json-stringify-symbols-and-react-components
                return typeof value === 'symbol' ? `$$Symbol:${Symbol.keyFor(value)}` : value;
            });
        }

        return undefined;
    }

    static format(data: string | undefined): JSX.Element[] {
        if (!data) {
            return [];
        }
    
        const parser = new DOMParser()
        const xml = parser.parseFromString(data, "text/xml");
        const elements = xml.getElementsByTagName("result");
        const result = new Map<string, IWarningItem[]>();
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            if (element.attributes.getNamedItem('errorcode')?.value !== '0') {
                if (element.parentElement) {
                    const value = {
                        id: element.parentElement?.attributes.getNamedItem('id')?.value,
                        name: element.parentElement?.attributes.getNamedItem('name')?.value,
                        processed: element.parentElement?.attributes.getNamedItem('processed')?.value,
                        result: element.attributes.getNamedItem('result')?.value,
                        errorText: element.attributes.getNamedItem('errortext')?.value,
                    };
    
                    const item = result.get(element.parentElement.nodeName);
                    if (item) {
                        item.push(value)
                    } else {
                        result.set(element.parentElement.nodeName, [value]);
                    }
                }
            }
        }
    
        const resultList: JSX.Element[] = [];
        result.forEach((value, key) => {
            resultList.push(
                <div style={{ paddingBottom: 5 }} key={key}>
                    <p key={0} style={compactStyle}><strong key={1}>{key}</strong></p>
                    {value.map((item, index) => {
                        return <div key={index}>
                            {item.id ? <p key={item.id + index} style={compactStyle}>Id: {item.id}</p> : ''}
                            {item.name ? <p key={item.name + index} style={compactStyle}>Name: {item.name}</p> : ''}
                            {item.processed ? <p key={item.processed + index} style={compactStyle}>Processed: {item.processed}</p> : ''}
                            {item.result ? <p key={item.result + index} style={compactStyle}>Result: {item.result}</p> : ''}
                            {item.errorText ? <p key={item.errorText + index} style={compactStyle}>Error: {item.errorText}</p> : ''}
                            {index < value.length - 1 ? <hr key={'index' + index} style={{ margin: 5 }} /> : ''}
                        </div>
                    })}
                </div>
            );
        });
    
        return resultList;
    }
} 